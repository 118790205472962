import { gql } from './__generated__';

export const COURSE_ELEMENT_WITH_ATTEMPTS_FRAGMENT = gql(/* GraphQL */ `
  fragment CourseElementWithAttempts on CourseElement {
    id
    order
    createdAt
    quiz {
      id
      name
      slug
      type {
        id
        name
      }
      subject {
        id
        name
      }
      attempts(filter: $quizAttemptFilter) {
        id
        createdAt
        status
      }
    }
    test {
      id
      name
      status
      slug
      type {
        id
        name
      }
      subject {
        id
        name
      }
      attempts(filter: $testAttemptFilter) {
        id
        status
        answerReportUrl
        scoreReportUrl
        createdAt
      }
    }
  }
`);

export const COURSE_ELEMENT_FRAGMENT = gql(/* GraphQL */ `
  fragment CourseListElement on CourseElement {
    id
    order
    createdAt
    quiz {
      id
      name
      slug
      attempts {
        id
        createdAt
        status
      }
    }
    test {
      id
      name
      status
      slug
      attempts {
        id
        status
        answerReportUrl
        scoreReportUrl
        createdAt
      }
    }
  }
`);

export const COURSE_FRAGMENT = gql(/* GraphQL */ `
  fragment Course on Course {
    archiveAt
    id
    name
    description
    subject
    userCount
    testCount
    inviteUrl
    status
    members {
      ...CourseMember
    }
    createdBy {
      name
    }
    elements {
      ...CourseListElement
    }
  }
`);

export const GET_COURSE = gql(/* GraphQL */ `
  query getCourse($input: GetCourseInput!) {
    course {
      getCourse(input: $input) {
        archiveAt
        id
        name
        description
        subject
        userCount
        testCount
        inviteUrl
        status
        createdBy {
          name
        }
        elements {
          ...CourseListElement
        }
      }
    }
  }
`);

export const GET_COURSE_MEMBERS = gql(/* GraphQL */ `
  query getCourseMembers(
    $filter: CourseMemberFilterInput!
    $paging: PagingInput
  ) {
    courseMember {
      getCourseMembers(filter: $filter, paging: $paging) {
        limit
        page
        total
        results {
          id
          createdAt
          user {
            id
            name
            email
          }
          enrolledBy {
            id
            name
          }
        }
      }
    }
  }
`);

export const COURSE_TABLE_FRAGMENT = gql(/* GraphQL */ `
  fragment CourseCore on Course {
    id
    name
    description
    subject
    userCount
    testCount
    archiveAt
  }
`);
export const GET_COURSES = gql(/* GraphQL */ `
  query getCourses(
    $filter: CourseFilterInput
    $paging: PagingInput
    $sort: CourseSortInput
  ) {
    course {
      getCourses(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          ...CourseCore
        }
      }
    }
  }
`);

export const CREATE_COURSE = gql(/* GraphQL */ `
  mutation createCourse($input: CreateCourseInput!) {
    course {
      create(input: $input) {
        id
      }
    }
  }
`);
export const UPDATE_COURSE = gql(/* GraphQL */ `
  mutation updateCourse($input: UpdateCourseInput!) {
    course {
      update(input: $input) {
        ...CourseCore
      }
    }
  }
`);

export const COPY_COURSE = gql(/* GraphQL */ `
  mutation copyCourse($input: CopyCoursesInput!) {
    course {
      copy(input: $input) {
        id
      }
    }
  }
`);

export const REMOVE_COURSE = gql(/* GraphQL */ `
  mutation deleteCourse($input: DeleteCourseInput!) {
    course {
      delete(input: $input) {
        affected
      }
    }
  }
`);

export const JOIN_COURSE = gql(/* GraphQL */ `
  mutation joinCourse($input: JoinByInviteInput!) {
    course {
      joinByInvite(input: $input) {
        id
      }
    }
  }
`);

export const ATTACH_ELEMENTS_COURSE = gql(/* GraphQL */ `
  mutation attachElementsCourse($input: AttachElementsInput!) {
    course {
      attachElements(input: $input) {
        id
        elements {
          ...CourseListElement
        }
      }
    }
  }
`);

export const DETACH_ELEMENTS_COURSE = gql(/* GraphQL */ `
  mutation detachElementsCourse($input: DeleteElementsInput!) {
    course {
      deleteElements(input: $input) {
        id
        elements {
          ...CourseListElement
        }
      }
    }
  }
`);

export const REORDER_ELEMENTS_COURSE = gql(/* GraphQL */ `
  mutation reorderElementsCourse($input: ReorderElementsInput!) {
    course {
      reorderElements(input: $input) {
        id
        elements {
          ...CourseListElement
        }
      }
    }
  }
`);

export const ENROLL_MEMBERS_COURSE = gql(/* GraphQL */ `
  mutation enrollMembersCourse($input: EnrollUsersInput!) {
    course {
      enrollMembers(input: $input) {
        id
      }
    }
  }
`);

export const DELETE_MEMBERS_COURSE = gql(/* GraphQL */ `
  mutation deleteMembersCourse($input: DeleteUsersInput!) {
    course {
      deleteMembers(input: $input) {
        id
      }
    }
  }
`);
