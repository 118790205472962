import Autocomplete, { IAutocompleteOption } from '../common/autocomplete.tsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_ANSWERS_COURSE_MEMBERS,
  GET_QUIZ_FILTERED_USERS,
  GET_TEST_FILTERED_USERS,
} from '../../apollo/answers-view.ts';
import { ElementType } from '../../utils/enums.ts';
import { getAutocompleteKey } from '../../utils/common-utils.ts';
import { uniqBy } from 'lodash';

type IProps = {
  type: ElementType;
  courseId?: number;
  element?: IAutocompleteOption;
  onChange: (value: IAutocompleteOption[]) => void;
};

export const UserSelect = ({ type, courseId, element, onChange }: IProps) => {
  const [userName, setUserName] = useState<string>();
  const [users, setUsers] = useState<IAutocompleteOption[]>([]);

  const { data: quizUsersData } = useQuery(GET_QUIZ_FILTERED_USERS, {
    skip: !!courseId || type !== ElementType.QUIZ || !element,
    variables: {
      input: { id: Number(getAutocompleteKey(element)) },
      filter: { userName },
    },
  });
  const { data: testUsersData } = useQuery(GET_TEST_FILTERED_USERS, {
    skip: !!courseId || type !== ElementType.TEST || !element,
    variables: {
      input: { id: Number(getAutocompleteKey(element)) },
      filter: { userName },
    },
  });
  const { data: courseMembersData } = useQuery(GET_ANSWERS_COURSE_MEMBERS, {
    skip: !courseId,
    variables: {
      // todo: create select with paging
      paging: { limit: Infinity },
      filter: { courseId },
    },
  });

  const newOptions = useMemo(() => {
    if (courseId) {
      return (
        courseMembersData?.courseMember?.getCourseMembers?.results?.reduce<
          { title: string; key: string }[]
        >((acc, member) => {
          if (member.user) {
            acc.push({
              title: member.user.name || member.user.email,
              key: member.user.id.toString(),
            });
          }
          return acc;
        }, []) || []
      );
    }
    if (type === ElementType.QUIZ) {
      return (
        quizUsersData?.quiz?.getQuiz?.attempts?.reduce<
          { title: string; key: string }[]
        >((acc, attempt) => {
          if (
            attempt.createdBy &&
            !acc.find((item) => item.key === attempt.createdBy?.id.toString())
          ) {
            acc.push({
              title: attempt.createdBy.name || attempt.createdBy.email,
              key: attempt.createdBy.id.toString(),
            });
          }
          return acc;
        }, []) || []
      );
    }
    if (type === ElementType.TEST) {
      return (
        testUsersData?.test?.getTest?.attempts?.reduce<
          { title: string; key: string }[]
        >((acc, attempt) => {
          if (
            attempt.createdBy &&
            !acc.find((item) => item.key === attempt.createdBy?.id.toString())
          )
            acc.push({
              title: attempt.createdBy.name || attempt.createdBy.email,
              key: attempt.createdBy.id.toString(),
            });
          return acc;
        }, []) || []
      );
    }
    return [];
  }, [
    courseMembersData?.courseMember?.getCourseMembers?.results,
    courseId,
    quizUsersData?.quiz?.getQuiz?.attempts,
    testUsersData?.test?.getTest?.attempts,
    type,
  ]);

  const usersOptions = useMemo(() => {
    const union = [...users, ...newOptions];
    return uniqBy(union, 'key');
  }, [newOptions, users]);

  const setValue = useCallback(
    (value: IAutocompleteOption | IAutocompleteOption[]) => {
      const users = Array.isArray(value) ? value : [value];
      onChange(users);
      setUsers(users);
    },
    [onChange],
  );

  useEffect(() => {
    if (courseId) {
      onChange(newOptions);
      setUsers(newOptions);
    }
  }, [courseId, onChange, newOptions]);

  return (
    <Autocomplete
      value={users}
      className={'mx-2 mt-2'}
      placeholder={'Select a users...'}
      options={usersOptions || []}
      disabled={!element}
      setValue={setValue}
      onChangeValue={setUserName}
    />
  );
};
