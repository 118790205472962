import { gql } from './__generated__';

export const GET_WATERMARK = gql(/* GraphQL */ `
  query getWatermark {
    watermark {
      get {
        id
        angle
        textSize
        logoSize
        textOpacity
        logoOpacity
        textCount
        logoIndex
        logo
        text
      }
    }
  }
`);

export const UPDATE_WATERMARK = gql(/* GraphQL */ `
  mutation updateWatermark($input: UpdateWatermarkInput!) {
    watermark {
      update(input: $input) {
        id
        angle
        textSize
        logoSize
        textOpacity
        logoOpacity
        textCount
        logoIndex
        logo
        text
      }
    }
  }
`);
