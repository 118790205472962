import Breadcrumbs from '../../../components/common/breadcrumbs.tsx';
import { useSuspenseQuery } from '@apollo/client';
import { GET_USER } from '../../../apollo/user.ts';
import { useNavigate, useParams } from 'react-router-dom';
import InfoTable, {
  IInfoTable,
} from '../../../components/common/info-table.tsx';
import Tabs from '../../../components/common/tabs.tsx';
import { UserActivity } from '../../../components/users/tabs/user-activity.tsx';
import UserCourses from '../../../components/users/tabs/user-courses.tsx';
import UserAssignments from '../../../components/users/tabs/user-assignments.tsx';
import UserPurchases from '../../../components/users/tabs/user-purchases.tsx';
import Button from '../../../components/common/button.tsx';
import RemoveModal from '../../../components/common/remove-modal.tsx';
import { useRemoveUser } from '../../../components/users/users-hooks.tsx';
import { useCallback, useMemo, useState } from 'react';
import Modal from '../../../components/common/modal.tsx';
import CreateUserForm from '../../../components/users/create-user-form.tsx';
import { useGetMe } from '../../../utils/hooks.ts';
import { UserRole } from '../../../apollo/__generated__/graphql.ts';
import UserAssets from '../../../components/users/tabs/user-assets.tsx';

const UserInformation = () => {
  const params = useParams();
  const navigate = useNavigate();
  const userId = Number(params?.userId);
  const { isAdmin, isTA } = useGetMe();
  const [isEditUser, setEditUser] = useState(false);

  const { data } = useSuspenseQuery(GET_USER, {
    variables: {
      input: {
        id: userId,
      },
    },
    errorPolicy: 'all',
  });
  const user = data?.user.getUser;

  const redirect = () => navigate('/users-browser');
  const { onOpenModal, isModalOpen, onCloseModal, onRemove } =
    useRemoveUser(redirect);

  const isHasPermissionForDeleteUser = isAdmin || isTA;

  const table: IInfoTable = useMemo(
    () => [
      {
        head: 'ID',
        value: user?.id,
      },
      {
        head: 'Name',
        value: user?.name ?? '',
      },
      {
        head: 'Email',
        value: user?.email,
      },
      {
        head: 'Role',
        value: (
          <div>
            {user?.roles.map((role) => (
              <span className={'mr-2'} key={role}>
                {role}
              </span>
            ))}
          </div>
        ),
      },
      {
        head: 'School name',
        value: user?.schoolName,
      },
      {
        head: 'Graduation year',
        value: user?.graduationYear,
      },
      {
        head: 'Parent email',
        value: user?.parentEmail,
      },
    ],
    [user],
  );

  const userTabs = useMemo(() => {
    const tabs = ['Activity', 'Courses', 'Assignments', 'Purchases'];
    if (user?.roles.includes(UserRole.Teacher)) {
      tabs.push('Assets');
    }
    return tabs;
  }, [user?.roles]);

  const onCloseCreateUser = useCallback(() => setEditUser(false), []);

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Users browser', href: '/users-browser' },
          { title: `${user?.name || user?.email} (ID:${userId})` },
        ]}
      />
      <div className={'mt-4 flex items-center justify-between'}>
        <div>
          <h1>User Information</h1>
          <p className={'description'}>
            Basic information and details about the user
          </p>
        </div>

        <div className={'flex items-center'}>
          {isHasPermissionForDeleteUser && (
            <Button
              disabled={!isHasPermissionForDeleteUser}
              onClick={onOpenModal}
              red
              className={'mr-2'}
            >
              Delete User
            </Button>
          )}

          <Button onClick={() => setEditUser(true)}>Edit User</Button>
        </div>
      </div>

      <InfoTable table={table} />

      <Tabs
        tabs={userTabs}
        components={[
          <UserActivity userId={userId} />,
          <UserCourses userId={userId} />,
          <UserAssignments userId={userId} />,
          <UserPurchases userId={userId} />,
          user?.roles.includes(UserRole.Teacher) && (
            <UserAssets userId={userId} />
          ),
        ]}
      />

      <Modal
        onClose={onCloseCreateUser}
        isOpen={isEditUser}
        title={'Edit User'}
      >
        <CreateUserForm
          onCloseModal={onCloseCreateUser}
          user={user}
          emailDisabled={true}
        />
      </Modal>

      <RemoveModal
        onRemove={() => user?.id && onRemove(user.id)}
        onClose={onCloseModal}
        open={isModalOpen}
        title={'Delete User?'}
      />
    </div>
  );
};
export default UserInformation;
