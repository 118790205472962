import { useNavigate, useParams } from 'react-router-dom';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { GET_QUESTION, REMOVE_QUESTION } from '../../../apollo/questions.ts';
import Button from '../../../components/common/button.tsx';
import { QuestionStatus } from '../../../apollo/__generated__/graphql.ts';
import { useCopyQuestion } from '../../../components/questions/questions-hooks.tsx';
import { useGetMe } from '../../../utils/hooks.ts';
import RemoveModal from '../../../components/common/remove-modal.tsx';
import { useState } from 'react';
import { toast } from 'react-toastify';
import CreateAPQuestionForm from '../../../components/questions/create-question/create-ap-question-form.tsx';

const EditAPQuestion = () => {
  const navigate = useNavigate();
  const params = useParams();
  const id = Number(params?.questionId);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const { isTeacher, isAdmin } = useGetMe();
  const { data } = useSuspenseQuery(GET_QUESTION, {
    variables: {
      input: {
        id,
      },
    },
    errorPolicy: 'all',
  });

  const question = data?.question?.getQuestion;
  const { openCopyModal, CopyModal } = useCopyQuestion(
    id,
    question?.originalId,
  );

  const [remove] = useMutation(REMOVE_QUESTION);

  const onRemoveQuestion = (id: number) => {
    const promise = remove({
      variables: {
        input: {
          ids: [id],
        },
      },
    }).then(() => {
      navigate('/questions-browser', { replace: true });
    });

    toast.promise(promise, {
      pending: `Deleting question...`,
      success: `Question removed`,
    });
  };

  return (
    <div className={'flex items-center justify-center'}>
      <div className={'w-[656px] max-w-[656px]'}>
        <section className={'flex justify-between'}>
          <div>
            <h1>{`Edit question ${
              question?.status === QuestionStatus.Draft ? ' draft' : ''
            } (QID: ${question?.originalId})`}</h1>
            <p className={'description'}>The process of editing a question</p>
          </div>
          <div className={'flex'}>
            <Button onClick={openCopyModal} className={'mx-2'}>
              Duplicate
            </Button>
            <Button
              disabled={!isAdmin && !isTeacher}
              onClick={() => setIsRemoveModal(true)}
              red
            >
              Delete
            </Button>
          </div>
        </section>
        <CreateAPQuestionForm editQuestion={question} />
      </div>

      <CopyModal />
      <RemoveModal
        title={'Remove Question?'}
        open={isRemoveModal}
        onClose={() => setIsRemoveModal(false)}
        onRemove={() => onRemoveQuestion(id)}
      />
    </div>
  );
};
export default EditAPQuestion;
