import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete, { IAutocompleteOption } from '../common/autocomplete.tsx';
import {
  cn,
  createXlsxExportUrl,
  getAutocompleteKey,
  getAutocompleteTitle,
  orderToLetter,
} from '../../utils/common-utils.ts';
import {
  useGetQuizViewData,
  useGetTestViewData,
} from './answers-view-hooks.ts';
import Loading from '../common/loading.tsx';
import Button from '../common/button.tsx';
import Table from '../table/table.tsx';
import {
  QuestionAnswerType,
  TestAttemptModuleLevel,
  TestSectionModuleLevel,
} from '../../apollo/__generated__/graphql.ts';
import { transformRatioToColor } from '../../utils/transform-ratio-to-color.ts';
import { TypeSelect } from './type-select.tsx';
import { ElementType } from '../../utils/enums.ts';
import { ElementSelect } from './element-select.tsx';
import { CourseSelect } from './course-select.tsx';
import { UserSelect } from './user-select.tsx';
import { useLazyQuery } from '@apollo/client';
import {
  GET_QUIZ_VIEW_DATA,
  GET_TEST_VIEW_DATA,
} from '../../apollo/answers-view.ts';

interface IProps {
  courseId?: number;
}

const columnsOptions: string[] = [
  'Domain',
  'Skill',
  'Type',
  'Subject',
  'Ans.',
  'Performance',
];

const AnswersView = ({ courseId }: IProps) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [selectedModules, setSelectedModules] = useState<string[]>();
  const [columns, setColumns] = useState<string[]>([
    'Domain',
    'Ans.',
    'Performance',
  ]);
  const [type, setType] = useState<ElementType>(ElementType.QUIZ);
  const [element, setElement] = useState<IAutocompleteOption>();
  const [course, setCourse] = useState<IAutocompleteOption>();
  const [users, setUsers] = useState<IAutocompleteOption[]>();

  const currentCourseId =
    courseId || (course ? Number(getAutocompleteKey(course)) : undefined);

  const quizId = useMemo(
    () =>
      element && type === ElementType.QUIZ
        ? Number(getAutocompleteKey(element))
        : undefined,
    [element, type],
  );

  const testId = useMemo(
    () =>
      element && type === ElementType.TEST
        ? Number(getAutocompleteKey(element))
        : undefined,
    [element, type],
  );

  const userIds: number[] = useMemo(() => {
    return users?.map((user) => Number(getAutocompleteKey(user))) || [];
  }, [users]);

  const [getQuizData, { data: quizData, loading: loadingQuiz }] = useLazyQuery(
    GET_QUIZ_VIEW_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          id: quizId,
        },
      },
    },
  );
  const { quizQuestionsSort, quizAttempts, quizPerformance } =
    useGetQuizViewData(quizData?.quiz.getQuiz, userIds);

  const [getTestData, { data: testData, loading: loadingTest }] = useLazyQuery(
    GET_TEST_VIEW_DATA,
    {
      fetchPolicy: 'network-only',
      variables: {
        input: {
          id: testId,
        },
      },
    },
  );
  const { test, testAttempts, modulesOptions, testPerformance } =
    useGetTestViewData(testData?.test.getTest, userIds);

  useEffect(() => {
    if (typeof selectedModules === 'undefined' && modulesOptions.length)
      setSelectedModules(modulesOptions);
  }, [modulesOptions, selectedModules]);

  const onChangeType = useCallback((type: ElementType) => {
    setType(type);
    setElement(undefined);
    setSelectedModules(undefined);
  }, []);

  const onChangeElement = useCallback((value: IAutocompleteOption) => {
    setElement(value);
    setSelectedModules(undefined);
  }, []);

  const onChangeColumns = (e: IAutocompleteOption | IAutocompleteOption[]) => {
    const isArray = Array.isArray(e);
    const key = getAutocompleteKey(isArray ? e[0] : e);
    if (!key || !isArray) return setColumns([]);
    setColumns(e as string[]);
  };

  const onChangeModulesColumns = (
    e: IAutocompleteOption | IAutocompleteOption[],
  ) => {
    const isArray = Array.isArray(e);
    const key = getAutocompleteKey(isArray ? e[0] : e);
    if (!key || !isArray) return setSelectedModules([]);
    setSelectedModules(e as string[]);
  };

  const getUsersColumns = useCallback(() => {
    return (
      users?.reduce<{ title: string; key: string }[]>((acc, user) => {
        const userName = getAutocompleteTitle(user);
        const userId = getAutocompleteKey(user);
        if (userId) acc.push({ title: userName || '', key: userId });
        return acc;
      }, []) ?? []
    );
  }, [users]);

  const getAttemptsForExport = useCallback(
    () =>
      users?.reduce<number[]>((acc, user) => {
        const userId = Number(getAutocompleteKey(user));
        let attemptId: number | undefined;
        if (type === ElementType.TEST) {
          attemptId = testAttempts.find(
            (attempt) => attempt.userId === userId,
          )?.attemptId;
        } else {
          attemptId = quizAttempts.find((a) => a.userId === userId)?.attemptId;
        }
        if (attemptId) acc.push(attemptId);
        return acc;
      }, []) || [],
    [quizAttempts, testAttempts, type, users],
  );

  const performanceColor = useCallback((performance?: number | null) => {
    return Number.isFinite(performance)
      ? `rgba(${transformRatioToColor(performance! / 100).join(',')}, 0.7)`
      : 'white';
  }, []);

  const exportReport = useCallback(() => {
    const url = createXlsxExportUrl(
      type === ElementType.TEST ? 'test' : 'quiz',
      Number(getAutocompleteKey(element)),
      getAttemptsForExport(),
    );
    window.open(url);
  }, [element, getAttemptsForExport, type]);

  const onRefresh = useCallback(() => {
    switch (type) {
      case ElementType.QUIZ:
        if (quizId) {
          getQuizData();
        }
        break;
      case ElementType.TEST:
        if (testId) {
          getTestData();
        }
        break;
    }
  }, [getQuizData, getTestData, quizId, testId, type]);

  useEffect(() => {
    onRefresh();
  }, [onRefresh, testId, quizId]);

  return (
    <div
      className={cn(
        'bg-white transition-all',
        isFullScreen && 'fixed left-0 top-0 h-full w-full bg-white p-4',
      )}
    >
      <div className={'flex h-10 items-center justify-between'}>
        <h1>Answers View</h1>
        <span>
          {(loadingTest || loadingQuiz) && <Loading isSmall={true} />}
        </span>
        <div className={'flex flex-row gap-2'}>
          <Button
            onClick={onRefresh}
            disabled={
              !(testId && userIds.length) && !(quizId && userIds.length)
            }
          >
            Refresh
          </Button>
          <Button
            red={isFullScreen}
            onClick={() => setIsFullScreen((prevState) => !prevState)}
          >
            {isFullScreen ? 'Disable full screen' : 'Open in full screen'}
          </Button>
        </div>
      </div>
      <section className={'mt-6 flex items-end'}>
        <div className={'flex w-72 flex-col'}>
          <TypeSelect onChange={onChangeType} />
          <ElementSelect
            type={type}
            onChange={onChangeElement}
            courseId={courseId}
          />
        </div>

        <div>
          {!courseId && (
            <CourseSelect disabled={!element} onChange={setCourse} />
          )}
          <UserSelect
            type={type}
            courseId={currentCourseId}
            element={element}
            onChange={setUsers}
          />
        </div>

        <Autocomplete
          value={columns}
          className={'mx-2 mt-2'}
          placeholder={'Columns...'}
          options={columnsOptions.sort()}
          setValue={onChangeColumns}
        />

        {!!modulesOptions.length && (
          <Autocomplete
            value={selectedModules}
            className={'mx-2 mt-2'}
            placeholder={'Columns...'}
            options={modulesOptions}
            setValue={onChangeModulesColumns}
          />
        )}

        <Button onClick={exportReport} className={'ml-5'}>
          Export
        </Button>
      </section>

      <hr className={'my-2 w-full opacity-10'} />

      <section className={'text-sm'}>
        {type === ElementType.QUIZ && (
          <Table
            isLoading={false}
            isEmpty={!quizQuestionsSort}
            sortKeys={[]}
            sort={{}}
            setSort={() => {}}
            columns={[
              { title: '#', key: '#' },
              ...columns.sort().map((item) => ({ title: item, key: item })),
              ...getUsersColumns(),
            ]}
          >
            {quizQuestionsSort?.map((item, index) => {
              const question = item.question;
              const correctChoice = question?.choices.find(
                (choice) => choice.correct,
              );
              const answer =
                question && correctChoice
                  ? question?.answerType === QuestionAnswerType.TextEntry
                    ? correctChoice.text
                    : orderToLetter(correctChoice.order)
                  : '';
              const performanceData = quizPerformance.find(
                (item) => item.questionId === question?.id,
              );

              return (
                <tr key={item.order}>
                  <td>{item.order + 1}</td>
                  {columns.map((col) => (
                    <Fragment key={col}>
                      {col === 'Domain' && <td>{question?.domain?.name}</td>}
                      {col === 'Skill' && <td>{question?.skill?.name}</td>}
                      {col === 'Type' && <td>{question?.type1?.name}</td>}
                      {col === 'Subject' && <td>{question?.subject?.name}</td>}
                      {col === 'Ans.' && <td>{answer}</td>}
                      {col === 'Performance' && (
                        <td
                          style={{
                            backgroundColor: performanceColor(
                              performanceData?.performance,
                            ),
                          }}
                        >
                          {performanceData?.performance &&
                          Number.isFinite(performanceData?.performance)
                            ? performanceData.performance.toFixed(2) + '%'
                            : '0.00%'}
                        </td>
                      )}
                    </Fragment>
                  ))}

                  {users?.map((user) => {
                    const userId = Number(getAutocompleteKey(user));
                    if (!userId) return null;
                    const attempt = quizAttempts.find(
                      (a) => a.userId === userId,
                    );
                    if (!attempt) {
                      return <td key={`${userId}${index}`}></td>;
                    }

                    const answer = quizAttempts
                      .find((i) => i.userId === userId)
                      ?.answers.find((q) => q.id === Number(question?.id));
                    return (
                      <td
                        key={`${userId}${index}`}
                        className={
                          answer?.isCorrect ? 'text-green' : 'text-red'
                        }
                      >
                        {answer?.answer}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </Table>
        )}

        {type === ElementType.TEST && (
          <Table
            isLoading={false}
            isEmpty={!test}
            sortKeys={[]}
            sort={{}}
            setSort={() => {}}
            columns={[
              { title: '#', key: '#' },
              ...columns.sort().map((item) => ({ title: item, key: item })),
              ...getUsersColumns(),
            ]}
          >
            {test?.sections?.map((section, sectionIndex) => {
              return section.modules.map((module, moduleIndex) => {
                if (
                  !selectedModules?.includes(
                    `${module.sectionName} - ${module.level}`,
                  )
                )
                  return null;
                return (
                  <Fragment key={section.name + module.level}>
                    <tr>
                      <td
                        colSpan={99}
                        className={
                          'text-md bg-light-blue text-center font-medium'
                        }
                      >
                        {module.sectionName + ' - ' + module.level}
                      </td>
                    </tr>
                    {module.questions.map((question, index) => {
                      return (
                        <tr key={question.id + module.level}>
                          <td>{index + 1}</td>
                          {columns.map((col) => {
                            const performanceData = testPerformance.find(
                              (item) =>
                                item.questionId === question.id &&
                                module.level === item?.moduleLevel,
                            );
                            return (
                              <Fragment key={col}>
                                {col === 'Domain' && (
                                  <td>{question?.domain}</td>
                                )}
                                {col === 'Skill' && <td>{question?.skill}</td>}
                                {col === 'Type' && <td>{question?.type}</td>}
                                {col === 'Subject' && (
                                  <td>{question?.subject}</td>
                                )}
                                {col === 'Ans.' && <td>{question.answer}</td>}
                                {col === 'Performance' && (
                                  <td
                                    style={{
                                      backgroundColor: performanceColor(
                                        performanceData?.performance,
                                      ),
                                    }}
                                  >
                                    {performanceData?.performance &&
                                    Number.isFinite(
                                      performanceData?.performance,
                                    )
                                      ? performanceData.performance.toFixed(2) +
                                        '%'
                                      : '0.00%'}
                                  </td>
                                )}
                              </Fragment>
                            );
                          })}

                          {users?.map((user) => {
                            const userId = Number(getAutocompleteKey(user));
                            const attempt = testAttempts.find(
                              (attempt) => attempt.userId === userId,
                            );
                            const answer = attempt?.answers.find(
                              (item) => item.id === question.id,
                            );
                            const isMathAdaptiveLevelWrong =
                              module.sectionName === 'math' &&
                              module.level !== TestSectionModuleLevel.All &&
                              TestAttemptModuleLevel[module.level] !==
                                attempt?.mathModuleLevel;
                            const isReadingAdaptiveLevelWrong =
                              module.sectionName === 'reading and writing' &&
                              module.level !== TestSectionModuleLevel.All &&
                              TestAttemptModuleLevel[module.level] !==
                                attempt?.rwModuleLevel;

                            if (
                              !answer ||
                              isMathAdaptiveLevelWrong ||
                              isReadingAdaptiveLevelWrong
                            ) {
                              return (
                                <td
                                  key={`${userId}${sectionIndex}${moduleIndex}${index}`}
                                ></td>
                              );
                            }
                            return (
                              <td
                                key={`${userId}${sectionIndex}${moduleIndex}${index}`}
                                className={
                                  answer.isCorrect ? 'text-green' : 'text-red'
                                }
                              >
                                {answer.answer}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </Fragment>
                );
              });
            })}
          </Table>
        )}

        {!quizQuestionsSort?.length && !test && (
          <p className={'mt-8 text-center text-blue'}>Select quiz or test</p>
        )}
      </section>
    </div>
  );
};
export default AnswersView;
