import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { GET_QUIZZES, REMOVE_QUIZ } from '../../apollo/quizzes.ts';
import { toast } from 'react-toastify';

export const useQuizzesFilter = () => {
  const initialFilter: {
    name?: string;
    description?: string;
    subjectId?: number;
    typeId?: number;
  } = {
    name: undefined,
    description: undefined,
    subjectId: undefined,
    typeId: undefined,
  };
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    if (type === 'name') setFilter({ ...initialFilter, name: value as string });
    if (type === 'description')
      setFilter({ ...initialFilter, description: value as string });
    if (type === 'subjectId') {
      setFilter({
        ...initialFilter,
        subjectId: value?.length ? Number(value) : undefined,
      });
    }
    if (type === 'typeId') {
      setFilter({
        ...initialFilter,
        typeId: value?.length ? Number(value) : undefined,
      });
    }
  };

  return { filter, onChangeFilter };
};

export const useRemoveQuizzes = (afterRemove?: () => void) => {
  const [removeModal, setRemoveModal] = useState(false);
  const onCloseRemove = () => setRemoveModal(false);
  const onOpenRemove = () => setRemoveModal(true);
  const [remove] = useMutation(REMOVE_QUIZ, {
    refetchQueries: [{ query: GET_QUIZZES }],
  });
  const onRemoveQuizzes = (ids: number[]) => {
    if (!ids.length) return null;
    const promise = remove({
      variables: {
        input: {
          ids,
        },
      },
    });

    const text = ids.length > 1 ? 'Quizzes' : 'Quiz';
    toast.promise(promise, {
      pending: `Deleting ${text}...`,
      success: `${text} deleted`,
    });

    promise.then(() => {
      if (afterRemove) afterRemove();
    });
  };

  return { onRemoveQuizzes, onOpenRemove, onCloseRemove, removeModal };
};
