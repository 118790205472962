import { useNavigate } from 'react-router-dom';
import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import { useFragment } from '../../apollo/__generated__';
import {
  CREATE_TEST,
  GET_TEST_TYPES,
  TEST_CORE_FRAGMENT,
  UPDATE_TEST,
} from '../../apollo/tests.ts';
import { useEffect, useState } from 'react';
import Button from '../common/button.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
// import RadioButton from '../common/radio-button.tsx';
import {
  Maybe,
  TestFragmentFragment,
} from '../../apollo/__generated__/graphql.ts';

interface IForm {
  name: string;
  typeId: number;
  subjectId: number;
}
interface IProps {
  test?: Maybe<TestFragmentFragment>;
  onCloseModal?: (slug: string) => void;
}
const CreateTestForm = ({ test, onCloseModal }: IProps) => {
  const navigate = useNavigate();

  const { data } = useSuspenseQuery(
    GET_TEST_TYPES,
    test ? skipToken : { errorPolicy: 'all' },
  );

  const [testSubjects, setTestSubjects] = useState(
    data?.test.testTypes[0].testSubjects,
  );
  const [createTest, { data: newTestData }] = useMutation(CREATE_TEST);
  const [updateTest] = useMutation(UPDATE_TEST);

  const newTest = useFragment(TEST_CORE_FRAGMENT, newTestData?.test.create);

  const {
    register,
    handleSubmit,
    formState: { errors },
    // watch,
  } = useForm<IForm>({
    defaultValues: {
      name: test?.name ? test.name : undefined,
      typeId: test?.type.id ? test.type.id : data?.test?.testTypes[0].id,
    },
  });

  // const testType = watch('typeId');

  useEffect(() => {
    const slug = newTest?.slug;
    if (slug) navigate(`/tests-browser/${slug}`);
  }, [navigate, newTest]);

  const onCreate: SubmitHandler<IForm> = (data) => {
    const create = createTest({
      variables: {
        input: {
          name: data.name,
          typeId: Number(data.typeId),
          subjectId: Number(data.subjectId),
        },
      },
    });

    toast.promise(create, {
      pending: 'Creating test...',
      success: 'Test created',
    });
  };

  const onUpdate: SubmitHandler<IForm> = (data) => {
    if (!test?.id) return;
    const update = updateTest({
      variables: {
        input: {
          id: test.id,
          name: data.name,
          status: test.status,
        },
      },
    });

    toast.promise(update, {
      pending: 'Updating test...',
      success: 'Test updated',
    });

    update.then((data) => {
      const slug = data.data?.test.update.slug;
      if (onCloseModal && slug) onCloseModal(slug);
    });
  };

  const handleTestTypeChange = (id: number) => {
    const selectedTestType = data?.test.testTypes.find(
      (type) => type.id === id,
    );
    setTestSubjects(selectedTestType?.testSubjects || []);
  };

  return (
    <div className={'w-[400px] p-4'}>
      {!test && (
        <>
          <h1>Create new Test</h1>
          <div className={'mt-4 grid grid-cols-1 gap-3'}>
            <div className={'mt-8'}>
              <label htmlFor="name" className={'font-medium'}>
                Test Type
              </label>
              <div className={'flex items-center'}>
                <select
                  id="test-subject"
                  className={
                    'block h-10 w-full rounded-lg border border-light-gray pl-2'
                  }
                  {...register('typeId', { required: true })}
                  onChange={(e) => {
                    handleTestTypeChange(Number(e.target.value));
                  }}
                  defaultValue={data?.test.testTypes[0].id}
                >
                  <option value="">Select a Test Type</option>
                  {data?.test.testTypes.map((type) => (
                    <option key={type.name} value={type.id}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>
              {errors.typeId && (
                <span className={'text-sm text-red'}>
                  This field is required
                </span>
              )}
            </div>
            <div className={'mt-8'}>
              <label htmlFor="name" className={'font-medium'}>
                Test Subject
              </label>
              <div className={'flex items-center'}>
                <select
                  id="test-subject"
                  className={
                    'block h-10 w-full rounded-lg border border-light-gray pl-2'
                  }
                  {...register('subjectId', { required: true })}
                  // defaultValue={testSubjects[0].name}
                >
                  <option value="">Select a Test Subject</option>
                  {testSubjects?.map((sub) => (
                    <option key={sub.name} value={Number(sub.id)}>
                      {sub.name}
                    </option>
                  ))}
                </select>
              </div>
              {errors.typeId && (
                <span className={'text-sm text-red'}>
                  This field is required
                </span>
              )}
            </div>
          </div>
        </>
      )}

      <div className={'mt-4'}>
        <label htmlFor="test-name">Name</label>
        <input
          type="text"
          className={'base-input'}
          placeholder={`'e.g. Math Test'`}
          {...register('name', { required: 'This field is required' })}
        />
        <p className={'h-4 text-sm text-red'}>
          {errors.name ? errors.name.message : ''}
        </p>
      </div>

      <Button
        type={'button'}
        onClick={test ? handleSubmit(onUpdate) : handleSubmit(onCreate)}
        className={'mt-8 w-full'}
      >
        {test ? 'Save' : 'Create'}
      </Button>
    </div>
  );
};
export default CreateTestForm;
