import { WatermarkParamsEnum } from './enums';

export const names = {
  satSections: {
    math: 'Math',
    'reading and writing': 'Reading and Writing',
  },
  answerType: {
    MultipleChoice: 'Multiple Choice',
    SingleChoice: 'Multiple Choice', // Customer requirement. It's not a dev mistake
    TextEntry: 'Free-Response (Typed)',
    HandWritten: 'Free-Response (Hand-written)',
  },
};

export const specialCharacter = ':';
