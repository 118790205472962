import Button from '../common/button.tsx';
import StatusBadge from '../common/status-badge.tsx';
import Modal from '../common/modal.tsx';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import QuestionAttempt from '../questions/question-content/question-attempt.tsx';
import { cn } from '../../utils/common-utils.ts';
import AttemptFinish from '../quiz-attempt/attempt-finish.tsx';
import AssignmentCardMenu from './assignment-card-menu.tsx';
import QuizQuestionsReview from '../quiz-attempt/quiz-questions-review.tsx';
import {
  QuizAttemptStatus,
  QuizStatus,
  TestAttemptStatus,
  TestStatus,
} from '../../apollo/__generated__/graphql.ts';
import { useStartNewTest } from '../tests/tests-hooks.tsx';
import { useCreateQuizAttempt } from '../quiz-attempt/quiz-attempt-hooks.ts';

type IAssignmentCardStatus =
  | 'In progress'
  | 'Completed'
  | 'Not started'
  | 'Archived';

export interface IAssigmentCard {
  id: number;
  title: string;
  type: 'Quiz' | 'Test' | 'Question';
  assignedOn: string;
  assignedBy: string;
  status: IAssignmentCardStatus;
  lastAttemptStatus?: TestAttemptStatus | QuizAttemptStatus;
  order?: number | null;
  testType?: string;
  subject?: string;
  test?: {
    slug: string;
    status?: TestStatus;
    lastAttemptId: number | undefined;
    scoreReportUrl: string | undefined | null;
    answersReportUrl: string | undefined | null;
  };
  quiz?: {
    slug: string;
    status?: QuizStatus;
    lastAttemptId: number | undefined;
  };
}

interface IProps {
  card: IAssigmentCard;
}

const AssignmentCard = ({ card }: IProps) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isQuizReview, setIsQuizReview] = useState(false);
  const [isQuestionsReview, setIsQuestionsReview] = useState(false);
  const startNewQuiz = useCreateQuizAttempt();
  const { startNewTest } = useStartNewTest();
  const toggleQuizReview = () => setIsQuizReview((prevState) => !prevState);
  const toggleQuestionsReview = () =>
    setIsQuestionsReview((prevState) => !prevState);
  const reviewAvailable =
    ['Completed', 'In progress'].includes(card.status) &&
    (card.test?.status === TestStatus.Active ||
      card.quiz?.status === QuizStatus.Active);

  const runAssignment = useCallback(() => {
    switch (card.type) {
      case 'Test':
        card.lastAttemptStatus === TestAttemptStatus.InProgress
          ? navigate(`/test-attempt/${card.test?.lastAttemptId}`)
          : startNewTest(card.id);
        break;
      case 'Quiz':
        card.lastAttemptStatus === QuizAttemptStatus.InProgress
          ? navigate(`/quiz-attempt/${card.quiz?.lastAttemptId}`)
          : startNewQuiz(card.id);
        break;
      case 'Question':
        setIsOpen(true);
        break;
    }
  }, [card, navigate]);

  const openReview = useCallback(() => {
    switch (card.type) {
      case 'Test':
        navigate(`/review-test/${card.test?.lastAttemptId}`);
        break;
      case 'Quiz':
        toggleQuestionsReview();
        break;
      case 'Question':
        setIsOpen(true);
        break;
    }
  }, [card, navigate]);

  const reviewAttemptsLink =
    card.status !== 'Archived'
      ? card.type === 'Quiz'
        ? `/quiz-attempts/${card.quiz?.slug}`
        : `/test-attempts/${card.test?.slug}`
      : undefined;

  const startButtonText = useMemo(() => {
    switch (card.lastAttemptStatus) {
      case QuizAttemptStatus.Completed || TestAttemptStatus.Completed:
        return 'Retake';
      case QuizAttemptStatus.InProgress || TestAttemptStatus.InProgress:
        return 'Continue';
      default:
        return 'Start';
    }
  }, [card.lastAttemptStatus]);

  return (
    <div className={'flex flex-col rounded-xl border border-light-gray p-4'}>
      <div className={'justify-between lg:flex'}>
        <div className={'flex w-[85%] items-center'}>
          <StatusBadge text={card.type} />
          <span className={'ml-2 truncate font-semibold'}>{card.title}</span>
        </div>
        <div className={'mt-2 flex text-xs lg:flex-col lg:items-end'}>
          <p>
            <span className={cn(card.status === 'Completed' && 'text-green')}>
              {card.status}
            </span>
            {card.status === 'Completed' && startButtonText === 'Continue' && (
              <span> &bull; New attempt in Progress</span>
            )}
          </p>
        </div>
      </div>

      <div className={'flex-row-reverse justify-between lg:flex'}>
        <div className={'mt-2 flex'}>
          {card.status === 'Archived' && card.type === 'Quiz' ? undefined : (
            <AssignmentCardMenu
              archived={card.status === 'Archived'}
              isShow={card.type !== 'Question'}
              scoreReportUrl={card.test?.scoreReportUrl}
              answersReportUrl={card.test?.answersReportUrl}
              reviewAttemptsLink={reviewAttemptsLink}
              isQuizReviewAvailable={card.status === 'Completed' && !!card.quiz}
              toggleQuizReviewModal={toggleQuizReview}
              lastCompletedTestAttemptId={
                card?.lastAttemptStatus === TestAttemptStatus.Completed
                  ? card.test?.lastAttemptId
                  : undefined
              }
              reviewLastTestAttemptLink={
                card.test?.lastAttemptId && card.status === 'Completed'
                  ? `/review-test/${card.test?.lastAttemptId}`
                  : undefined
              }
            />
          )}

          {reviewAvailable && (
            <Button white={true} onClick={openReview} className={'ml-1 w-20'}>
              Review
            </Button>
          )}

          {card.status !== 'Archived' && (
            <Button
              onClick={runAssignment}
              icon={'arrow'}
              className={'ml-1 w-24'}
            >
              {startButtonText}
            </Button>
          )}
        </div>

        <div className={'mt-2 text-xs'}>
          <p>{card.assignedOn}</p>
          <p>{card.assignedBy}</p>
          <p>{card?.testType}</p>
          <p>{card?.subject}</p>
        </div>
      </div>

      <Modal
        title={
          card.status === 'Completed'
            ? 'Review of completed question'
            : 'Question Attempt'
        }
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        fullScreen
      >
        <QuestionAttempt questionId={card.id} />
      </Modal>

      <Modal
        title={'Quiz Review'}
        onClose={toggleQuizReview}
        isOpen={isQuizReview}
      >
        <AttemptFinish
          attemptId={Number(card.quiz?.lastAttemptId)}
          isReviewMode={true}
        />
      </Modal>

      <Modal
        title={'Questions Review'}
        onClose={toggleQuestionsReview}
        isOpen={isQuestionsReview}
      >
        <QuizQuestionsReview quizAttemptId={Number(card.quiz?.lastAttemptId)} />
      </Modal>
    </div>
  );
};
export default AssignmentCard;
