import { SubmitHandler, useForm } from 'react-hook-form';
import Button from '../../common/button.tsx';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { CREATE_REPORT } from '../../../apollo/reports.ts';
import { QuestionReportErrorType } from '../../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';
import { GET_TEST_TYPES } from '../../../apollo/tests.ts';
import { useState } from 'react';

interface IForm {
  type: QuestionReportErrorType;
  description: string;
  typeId: number;
  subjectId: number;
}

interface IProps {
  questionId?: number;
  close: () => void;
}
const QuestionReportForm = ({ questionId, close }: IProps) => {
  const [create] = useMutation(CREATE_REPORT);

  const { data: test_data } = useSuspenseQuery(GET_TEST_TYPES, {
    errorPolicy: 'all',
  });

  const [testSubjects, setTestSubjects] = useState(
    test_data?.test.testTypes[0].testSubjects,
  );

  const { register, handleSubmit } = useForm<IForm>({
    defaultValues: {
      type: QuestionReportErrorType.Typo,
      description: '',
    },
  });

  const onSubmit: SubmitHandler<IForm> = (data) => {
    if (!questionId) {
      toast.error('Question ID not specified');
      return;
    }
    const promise = create({
      variables: {
        input: {
          questionId,
          description: data.description,
          errorType: data.type,
          typeId: +data.typeId,
          subjectId: +data.subjectId,
        },
      },
    });

    toast.promise(promise, {
      pending: `Creating report...`,
      success: `Report created`,
    });

    promise.then(() => close());
  };

  const options: QuestionReportErrorType[] = [
    QuestionReportErrorType.Typo,
    QuestionReportErrorType.Logic,
    QuestionReportErrorType.Formatting,
  ];
  const handleTestTypeChange = (id: number) => {
    const selectedTestType = test_data?.test.testTypes.find(
      (type) => type.id === id,
    );
    setTestSubjects(selectedTestType?.testSubjects || []);
  };

  return (
    <form className={'mt-4 w-80 p-2'}>
      <div className={'mt-4 flex flex-col'}>
        <label className={'my-1 text-sm font-medium'} htmlFor="error-type">
          Error type*
        </label>
        <select
          id={'error-type'}
          className={'h-10 rounded-lg border border-disabled pl-2'}
          {...register('type')}
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>

      <div className={'mt-4 flex flex-col'}>
        <label htmlFor="name" className={'font-medium'}>
          Test Type
        </label>
        <div className={'flex items-center'}>
          <select
            id="test-subject"
            className={
              'block h-10 w-full rounded-lg border border-light-gray pl-2'
            }
            {...register('typeId', { required: true })}
            onChange={(e) => {
              handleTestTypeChange(Number(e.target.value));
            }}
            defaultValue={test_data?.test.testTypes[0].id}
          >
            <option value="">Select a Test Type</option>
            {test_data?.test.testTypes.map((type) => (
              <option key={type.name} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        {/* {errors.typeId && (
          <span className={'text-sm text-red'}>This field is required</span>
        )} */}
      </div>

      <div className={'mt-4 flex flex-col'}>
        <label htmlFor="name" className={'font-medium'}>
          Test Subject
        </label>
        <div className={'flex items-center'}>
          <select
            id="test-subject"
            className={
              'block h-10 w-full rounded-lg border border-light-gray pl-2'
            }
            {...register('subjectId', { required: true })}
            // defaultValue={testSubjects[0].name}
          >
            <option value="">Select a Test Subject</option>
            {testSubjects?.map((sub) => (
              <option key={sub.name} value={Number(sub.id)}>
                {sub.name}
              </option>
            ))}
          </select>
        </div>
        {/* {errors.typeId && (
          <span className={'text-sm text-red'}>This field is required</span>
        )} */}
      </div>

      <div className={'mt-6 flex flex-col'}>
        <label
          className={'my-1 text-sm font-medium'}
          htmlFor="error-description"
        >
          Description
        </label>
        <textarea
          placeholder={'Enter Description...'}
          className={'h-32 max-h-80 rounded-lg border border-light-gray p-1'}
          {...register('description', { required: true })}
        />
      </div>

      <div className={'mt-6 grid grid-cols-2 gap-3'}>
        <Button onClick={close} white>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onSubmit)}>Send</Button>
      </div>
    </form>
  );
};
export default QuestionReportForm;
