import { useEffect, useState } from 'react';
import { Editors } from '../../../editor/editors.tsx';

interface IMultipleChoice {
  answer?: string;
  numberOfColumns?: number;
  onChange: (text: string) => void;
}

const MultipleChoiceHeader = ({
  answer = '',
  numberOfColumns,
  onChange,
}: IMultipleChoice) => {
  const [text, setText] = useState(answer);

  useEffect(() => {
    onChange(text);
  }, [text]);

  return (
    <div className={'mx-[85px] mt-8 flex items-center'}>
      <Editors
        numberOfColumns={numberOfColumns}
        setText={setText}
        defaultMarkdownValue={answer!}
        // className={'min-h-[50px]'}
      />
    </div>
  );
};

export default MultipleChoiceHeader;
