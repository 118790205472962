import {
  DELETE_MEMBERS_COURSE,
  ENROLL_MEMBERS_COURSE,
  GET_COURSE_MEMBERS,
} from '../../../apollo/courses.ts';
import Button from '../../common/button.tsx';
import CopyIcon from '../../../assets/icons/copy.svg?react';
import { toast } from 'react-toastify';
import EnrolledStudentsIcon from '../../../assets/icons/enrolled-students.svg?react';
import InviteStudentsIcon from '../../../assets/icons/invite-students.svg?react';
import Tooltip from '../../common/tooltip.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../../common/modal.tsx';
import UsersBrowser from '../../../routes/admin/users/users-browser.tsx';
import { useMutation, useQuery } from '@apollo/client';
import RemoveButton from '../../common/remove-button.tsx';
import UserAvatar from '../../users/user-avatar.tsx';
import RemoveModal from '../../common/remove-modal.tsx';
import QuizScoresModal from './quiz-scores-modal.tsx';
import TestScoresModal from './test-scores-modal.tsx';
import { GetCourseQuery } from '../../../apollo/__generated__/graphql.ts';
import { uniqBy } from 'lodash';
import {
  useDeleteItems,
  useFetchMore,
  useGetMe,
} from '../../../utils/hooks.ts';

interface IProps {
  course?: GetCourseQuery['course']['getCourse'];
}

interface IUserInfo {
  id: number;
  name: string;
}

const StudentsTab = ({ course }: IProps) => {
  const FETCH_LIMIT = 20;
  const { data, fetchMore } = useQuery(GET_COURSE_MEMBERS, {
    variables: {
      filter: {
        courseId: course?.id,
      },
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
    },
  });
  const [page, setPage] = useState(1);
  const [isUsersBrowser, setUserBrowser] = useState(false);
  const [idToRemove, setIdToRemove] = useState<number>();
  const [users, setUsers] = useState<number[]>([]);
  const { setIsDeleted, onDelete } = useDeleteItems();
  const { isStaff, isAdmin, isTeacher, isTA } = useGetMe();
  const [enroll] = useMutation(ENROLL_MEMBERS_COURSE, {
    refetchQueries: [GET_COURSE_MEMBERS],
  });
  const [deleteMember] = useMutation(DELETE_MEMBERS_COURSE, {
    refetchQueries: [GET_COURSE_MEMBERS],
  });

  const onCloseRemoveModal = () => setIdToRemove(undefined);
  const onCloseBrowser = () => {
    setUsers([]);
    setUserBrowser(false);
  };

  const onEnroll = () => {
    if (!course) return null;
    const promise = enroll({
      variables: {
        input: {
          id: course.id,
          userIds: users,
        },
      },
    });

    const text = users.length > 1 ? 'Users' : 'Users';
    toast.promise(promise, {
      pending: `Adding ${text}...`,
      success: `${text} added`,
    });

    promise.then(() => {
      onCloseBrowser();
      setPage(1);
    });
  };

  const [userForReviewQuiz, setUserIdForReviewQuiz] =
    useState<IUserInfo | null>(null);
  const [userForReviewTest, setUserIdForReviewTest] =
    useState<IUserInfo | null>(null);

  const members = data?.courseMember.getCourseMembers.results;

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.courseMember.getCourseMembers.total);
    if (total && Number(members?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.courseMember.getCourseMembers.results)
            return prev;
          const union = [
            ...(prev.courseMember?.getCourseMembers?.results || []),
            ...fetchMoreResult.courseMember.getCourseMembers.results,
          ];
          const unique = uniqBy(union, 'id');

          if (unique.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            courseMember: {
              ...prev.courseMember,
              getCourseMembers: {
                ...fetchMoreResult.courseMember.getCourseMembers,
                results: unique,
              },
            },
          };
        },
      });
    }
  };

  const { ref } = useFetchMore(onFetchMore, onDelete);

  const onDeleteMember = () => {
    if (!idToRemove || !course) return;
    const promise = deleteMember({
      variables: {
        input: {
          id: course.id,
          memberIds: [idToRemove],
        },
      },
    });

    toast
      .promise(promise, {
        pending: `Deleting user from course...`,
        success: `User deleted from course`,
      })
      .then(() => {
        setIdToRemove(undefined);
        setPage(1);
        setIsDeleted(true);
      });
  };

  return (
    <div className={'mt-6'}>
      <div className={'flex'}>
        <InviteStudentsIcon className={'mr-2'} />
        <h2 className={'text-lg font-semibold'}>Invite Students</h2>
      </div>
      <p className={'description'}>
        Students can join the course by visiting this pre-approved invitation
        url
      </p>
      <div className={'flex items-center'}>
        {course?.inviteUrl && (
          <input
            type="text"
            readOnly={true}
            value={course?.inviteUrl}
            className={'base-input mt-2 max-w-sm text-sm'}
          />
        )}
        <Tooltip tip={'Copy invite URL'}>
          <button
            onClick={() => {
              if (!course?.inviteUrl) return null;
              navigator.clipboard
                .writeText(course?.inviteUrl)
                .then(() => toast.success('Copied'));
            }}
            className={'mx-2 mt-2 p-1'}
          >
            <CopyIcon />
          </button>
        </Tooltip>
      </div>

      <div className={'mt-6 flex items-center justify-between'}>
        <div className={'flex'}>
          <EnrolledStudentsIcon className={'mr-2'} />
          <h2 className={'text-lg font-semibold'}>Enrolled Students</h2>
        </div>

        <Button onClick={() => setUserBrowser(true)}>Enroll student</Button>
      </div>

      <div className={'mb-10 mt-2'}>
        {members?.map((member, index, array) => {
          return (
            !!member.user && (
              <div
                key={member.id}
                ref={index === array.length - 5 ? ref : null}
                className={
                  'mt-1 flex items-center justify-between rounded-lg border border-table-border p-2 text-sm'
                }
              >
                <div className={'flex'}>
                  <UserAvatar name={member.user.name || member.user.email} />
                  <div className={'ml-2'}>
                    <p className={'font-semibold text-blue'}>
                      {member.user.name || member.user.email}
                    </p>
                    <p>{getDateString(member.createdAt)}</p>
                  </div>
                </div>

                <div className={'flex'}>
                  {(isAdmin || isTeacher || isStaff || isTA) && (
                    <>
                      <Button
                        white
                        onClick={() => {
                          if (member.user) {
                            setUserIdForReviewQuiz({
                              name: member.user.name || member.user.email,
                              id: member.user.id,
                            });
                          }
                        }}
                      >
                        Quiz scores
                      </Button>
                      <Button
                        white
                        className={'mx-2'}
                        onClick={() => {
                          if (member.user) {
                            setUserIdForReviewTest({
                              name: member.user.name || member.user.email,
                              id: member.user.id,
                            });
                          }
                        }}
                      >
                        Test scores
                      </Button>
                    </>
                  )}
                  <RemoveButton
                    onClick={() => setIdToRemove(member.id)}
                    disabled={isStaff}
                  />
                </div>
              </div>
            )
          );
        })}
        {!members?.length && (
          <div className={'mt-4 text-center text-sm'}>No students yet</div>
        )}
      </div>

      <Modal
        title={'Add users in course'}
        onClose={onCloseBrowser}
        isOpen={isUsersBrowser}
        fullScreen
      >
        <div>
          <Button
            onClick={onEnroll}
            disabled={!users.length}
            className={'ml-auto mt-2'}
          >
            {users.length > 1 ? 'Add users' : 'Add user'} ({users.length})
          </Button>
          <UsersBrowser isModalMode={true} setSelectedUsers={setUsers} />
        </div>
      </Modal>

      <Modal
        title={`Quiz Scores for ${userForReviewQuiz?.name}`}
        onClose={() => setUserIdForReviewQuiz(null)}
        isOpen={!!userForReviewQuiz}
      >
        <QuizScoresModal userId={userForReviewQuiz?.id} courseId={course?.id} />
      </Modal>

      <Modal
        title={`Test Scores for ${userForReviewTest?.name}`}
        onClose={() => setUserIdForReviewTest(null)}
        isOpen={!!userForReviewTest}
      >
        <TestScoresModal userId={userForReviewTest?.id} courseId={course?.id} />
      </Modal>

      <RemoveModal
        onRemove={onDeleteMember}
        onClose={onCloseRemoveModal}
        description={'Are you sure you want to remove user from this course?'}
        open={!!idToRemove}
      />
    </div>
  );
};
export default StudentsTab;
