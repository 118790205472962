import { UpdateParamsProps, WatermarkGlobalParamsProps } from './types';
import { WatermarkParamsEnum as Params } from '../../../../utils/enums';
import { useMutation } from '@apollo/client';
import { UPDATE_WATERMARK } from '../../../../apollo/watermark';
import { toast } from 'react-toastify';

export const useWatermarkGlobalParams = ({
  params,
  setParams,
}: WatermarkGlobalParamsProps) => {
  const [update, mutateDate] = useMutation(UPDATE_WATERMARK);

  const paramsNumberValue = [
    { param: Params.ANGLE, max: 360, min: -360 },
    { param: Params.LOGO_OPACITY, max: 100, min: 0 },
    { param: Params.TEXT_OPACITY, max: 100, min: 0 },
    { param: Params.LOGO_SIZE, max: 100, min: 0 },
    { param: Params.TEXT_SIZE, max: 100, min: 0 },
    {
      param: Params.LOGO_INDEX,
      max: params.textCount,
      min: 0,
    },
    { param: Params.TEXT_COUNT, max: 10, min: 1 },
  ];

  const paramsBooleanValue = [Params.LOGO, Params.TEXT];

  const updateParams = (data: UpdateParamsProps) => {
    const updatedData = { ...data };

    if (updatedData.textCount && params.logoIndex > updatedData.textCount) {
      updatedData.logoIndex = updatedData.textCount;
    }

    setParams({ ...params, ...updatedData });
  };

  const onSubmit = () => {
    const { __typename, ...payload } = params;

    const promise = update({
      variables: {
        input: {
          ...payload,
        },
      },
    });

    toast.promise(promise, {
      pending: 'Updating...',
      success: 'Watermark updated',
    });
  };

  return {
    onSubmit,
    updateParams,
    paramsBooleanValue,
    paramsNumberValue,
    isLoading: mutateDate.loading,
  };
};
