import { SubmitHandler, useController, useForm } from 'react-hook-form';
import {
  ChoicesFragmentFragment,
  CreateQuestionChoiceInput,
  CreateQuestionInput,
  GetQuestionQuery,
  QuestionAnswerType,
  QuestionStatus,
  QuestionUsageType,
  UpdateQuestionInput,
} from '../../../apollo/__generated__/graphql.ts';
import RadioButton from '../../common/radio-button.tsx';
import InfoIcon from '../../../assets/icons/info.svg?react';
import { names } from '../../../utils/constants.ts';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CHOICES_FRAGMENT,
  CREATE_QUESTION,
  GET_CREATE_QUESTIONS_DATA,
  QUESTION_FRAGMENT,
  UPDATE_QUESTION,
} from '../../../apollo/questions.ts';
import Toggle from '../../common/toggle.tsx';
import AddHeader from '../../common/addHeaderColumn.tsx';
import { useState } from 'react';
import { Editor } from '../../editor/editor.tsx';
import MultipleChoices from './multiple-choices/multiple-choices.tsx';
import Button from '../../common/button.tsx';
import TagsEditor from './tags-editor.tsx';
import { toast } from 'react-toastify';
import getQuestionSelects from './questions-selects.tsx';
import Tooltip from '../../common/tooltip.tsx';
import { useFragment } from '../../../apollo/__generated__';
import { questionUsageTypes } from '../questions-constants.ts';
import {
  createEmptyMultipleAnswers,
  createNewMultipleAnswer,
} from '../question-utils.ts';
import QuestionContent from '../question-content/question-content.tsx';
import Modal from '../../common/modal.tsx';
import { cn } from '../../../utils/common-utils.ts';
import { useNavigate } from 'react-router-dom';
import { CreateQuestionToast } from './create-question-toast.tsx';
import { GET_TEST_TYPES } from '../../../apollo/tests.ts';
import AddColumn from '../../common/addColumn.tsx';
import MultipleChoiceHeader from './multiple-choices/multiple-choice-header.tsx';

export interface IMultipleAnswer extends CreateQuestionChoiceInput {
  id: string;
}

const createMultipleAnswers = (
  choices: readonly ChoicesFragmentFragment[],
): IMultipleAnswer[] => {
  const arr: IMultipleAnswer[] = [];
  choices.forEach((choice) => {
    arr.push(createNewMultipleAnswer(0, choice));
  });
  return arr;
};

interface IFormData extends CreateQuestionInput {
  answer: string;
}

interface ICreateQuestionForm {
  editQuestion?: GetQuestionQuery['question']['getQuestion'];
  params?: URLSearchParams;
}

const CreateAPQuestionForm = ({
  editQuestion,
  params,
}: ICreateQuestionForm) => {
  const navigate = useNavigate();
  const question = useFragment(QUESTION_FRAGMENT, editQuestion);

  const [updateQuestion, { loading: updateLoading }] =
    useMutation(UPDATE_QUESTION);
  const [createQuestion, { loading: createLoading }] =
    useMutation(CREATE_QUESTION);
  const choices = useFragment(CHOICES_FRAGMENT, question?.choices);
  const [tags, setTags] = useState<string[]>(
    question?.tags?.map((tag) => tag.name) ?? [],
  );
  const [addHeader, setAddHeader] = useState(question?.header ? true : false);
  const [addColumn, setAddColumn] = useState<number>(
    choices && choices[0].text ? choices[0].text.split(':').length : 1,
  );

  const [heading, setHeading] = useState<string>(question?.header || '');
  const [isPreview, setIsPreview] = useState(false);
  const [isAdvancedOptions, setAdvancedOptions] = useState(false);
  const [isQuestionContentEditor, setQuestionContentEditor] =
    useState<boolean>(true);

  const [multipleAnswers, setMultipleAnswers] = useState<IMultipleAnswer[]>(
    question?.answerType === QuestionAnswerType.MultipleChoice
      ? createMultipleAnswers(choices || [])
      : createEmptyMultipleAnswers(),
  );
  const { data } = useSuspenseQuery(GET_CREATE_QUESTIONS_DATA, {
    errorPolicy: 'all',
  });

  const { data: test_data } = useSuspenseQuery(GET_TEST_TYPES, {
    errorPolicy: 'all',
  });
  const isDraft = question?.status === QuestionStatus.Draft;
  const typeId = test_data?.test.testTypes.find((el) => el.name == 'AP')?.id;

  const { register, handleSubmit, setValue, watch, control } =
    useForm<IFormData>({
      defaultValues: {
        usageType:
          question?.usageType ||
          (params?.get('usageType')
            ? QuestionUsageType[
                params.get('usageType') as keyof typeof QuestionUsageType
              ]
            : QuestionUsageType.Mock),
        sectionId:
          Number(question?.section?.id) ||
          Number(params?.get('sectionId')) ||
          data?.question.satSections[0].id,
        answerType: question?.answerType || QuestionAnswerType.MultipleChoice,
        content: question?.content || '',
        prompt: question?.prompt || '',
        domainId:
          Number(question?.domain?.id) ||
          Number(params?.get('domainId')) ||
          undefined,
        skillId:
          Number(question?.skill?.id) ||
          Number(params?.get('skillId')) ||
          undefined,
        type1Id: Number(question?.type1?.id) || undefined,
        type2Id: Number(question?.type2?.id) || undefined,
        subjectId: Number(question?.subject?.id) || undefined,
        difficultyId: question?.difficulty.id || 1,
        answer:
          question?.answerType !== QuestionAnswerType.TextEntry
            ? ''
            : choices?.[0]?.text || '',
        explanation: question?.explanation || '',
        sourceType: question?.sourceType ? question.sourceType : null,
        sourceQuestionId: question?.sourceQuestion?.originalId,
        header: question?.header || '',
      },
    });

  const contentInput = useController({ control, name: 'content' });
  const promptInput = useController({ control, name: 'prompt' });
  const explainInput = useController({ control, name: 'explanation' });
  const answerInput = useController({ control, name: 'answer' });
  // const headingInput = useController({ control, name: 'header' });
  const onSubmit: SubmitHandler<IFormData> = (data) => {
    let error: string | null = null;
    if (data.answerType === QuestionAnswerType.MultipleChoice) {
      if (multipleAnswers.find((answer) => !answer.text))
        error = 'Choice text is required';
      if (!multipleAnswers.find((answer) => answer.correct))
        error = 'You need to indicate which of the answers is correct';
    }
    if (!data.prompt) error = 'Question prompt is required';
    if (data.answerType === QuestionAnswerType.TextEntry && !data.answer)
      error = 'Answer is required';

    if (error) {
      toast.error(error);
      return;
    }

    const { answer, ...formData } = data;

    if (question) {
      const questionUpdateInput: UpdateQuestionInput = {
        ...formData,
        sectionId: Number(data.sectionId) || undefined,
        sourceQuestionId: Number(data.sourceQuestionId) || undefined,
        sourceType: data.sourceType || undefined,
        id: question!.id,
        tags,
        choices:
          data.answerType === QuestionAnswerType.MultipleChoice
            ? multipleAnswers.map((answer, index) => ({
                text: answer.text,
                correct: answer.correct,
                order: index,
                id: Number(answer.id),
              }))
            : [{ text: answer, order: 0, correct: true, id: choices![0].id }],
        typeId: +typeId!,
        header: addHeader === true ? heading : '',
      };

      const updateMutate = updateQuestion({
        variables: {
          input: questionUpdateInput,
        },
      });
      updateMutate.catch((e) => console.error('mutate error:', e?.message));
      toast.promise(updateMutate, {
        pending: 'Saving...',
        success: 'Question updated',
      });
    } else {
      const createQuestionInput: CreateQuestionInput = {
        ...formData,
        sectionId: Number(data.sectionId) || undefined,
        sourceQuestionId: Number(data.sourceQuestionId) || undefined,
        sourceType: data.sourceType || undefined,
        tags,
        choices:
          data.answerType === QuestionAnswerType.MultipleChoice
            ? multipleAnswers.map((answer, index) => ({
                text: answer.text,
                correct: answer.correct,
                order: index,
              }))
            : [{ text: answer, order: 0, correct: true }],
        typeId: +typeId!,
        header: addHeader === true ? heading : '',
      };
      const mutate = createQuestion({
        variables: {
          input: createQuestionInput,
        },
      });
      mutate
        .then((data) => {
          navigate(`/edit-question/${data?.data?.question?.create?.id}`);
        })
        .catch((e) => console.error('mutate error:', e?.message));
      toast.promise(mutate, {
        pending: 'Creating...',
        success: {
          autoClose: false,
          icon: false,
          render: ({ data, closeToast }) => {
            return (
              <CreateQuestionToast
                questionId={data?.data?.question?.create?.originalId}
                usageType={questionType}
                domain={domainId}
                skill={skillId}
                section={satSectionId}
                closeToast={closeToast}
              />
            );
          },
        },
      });
    }
  };

  const answerTypes = [
    QuestionAnswerType.MultipleChoice,
    QuestionAnswerType.TextEntry,
    QuestionAnswerType.HandWritten,
  ];

  const questionType = watch('usageType');
  const satSectionId = watch('sectionId');
  const answerType = watch('answerType');
  const domainId = watch('domainId');
  const skillId = watch('skillId');
  const answer = watch('answer');

  // useEffect(() => {
  //   setQuestionContentEditor(Number(satSectionId) === 2);
  // }, [satSectionId]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <section>
        <div className={'mt-3 flex items-center'}>
          <h3 className={'mr-2 font-semibold'}>Question type</h3>

          <Tooltip
            tip={
              <div>
                <p>- Mock questions are to be used on DSAT mock tests</p>
                <p>
                  - Quiz questions are to be used for other purposes, such as
                  quizzes or class practice
                </p>
              </div>
            }
          >
            <span>
              <InfoIcon />
            </span>
          </Tooltip>
        </div>

        <div className={'mt-2 grid grid-cols-2 gap-2'}>
          {questionUsageTypes.map((label) => {
            return (
              <RadioButton
                key={label}
                label={label}
                value={label}
                checked={questionType === label}
                register={register('usageType')}
              />
            );
          })}
        </div>
      </section>

      <section>
        <h3 className={'mr-2 mt-3 font-semibold'}>Sat Section</h3>
        <div className={'mt-2 grid grid-cols-2 gap-2'}>
          {data?.question?.satSections?.map((section) => {
            const label =
              names.satSections[
                section.name as keyof typeof names.satSections
              ] || section.name;
            return (
              <RadioButton
                key={section.id}
                value={section.id}
                label={label}
                checked={Number(satSectionId) === section.id}
                register={register('sectionId', {
                  onChange: () => {
                    setValue('domainId', undefined);
                    setValue('skillId', undefined);
                    setValue('type1Id', undefined);
                    setValue('type2Id', undefined);
                    setValue('answerType', QuestionAnswerType.MultipleChoice);
                  },
                })}
              />
            );
          })}
        </div>
      </section>

      <section className={'mt-4 grid grid-cols-2 gap-2'}>
        {getQuestionSelects(
          Number(satSectionId),
          Number(domainId),
          Number(skillId),
          data,
        ).map((select) => {
          return (
            <div key={select.label} className={'mt-4 flex flex-col'}>
              <label className={'text-xs font-semibold'}>{select.label}</label>
              <select
                className={'h-10 rounded-lg border border-light-gray'}
                defaultValue={''}
                {...register(select.registerName as keyof CreateQuestionInput, {
                  valueAsNumber: true,
                  onChange: (e) => {
                    if (e.target.name === 'domainId') {
                      setValue('skillId', undefined);
                      setValue('type1Id', undefined);
                      setValue('type2Id', undefined);
                    }
                    if (e.target.name === 'skillId') {
                      setValue('type1Id', undefined);
                      setValue('type2Id', undefined);
                    }
                  },
                })}
              >
                {select.registerName !== 'difficultyId' && (
                  <option value={''}>Choose an option</option>
                )}
                {select.options?.map((option) => (
                  <option value={option.id} key={option.id}>
                    {select.registerName === 'difficultyId'
                      ? option.id
                      : option.name}
                  </option>
                ))}
              </select>
            </div>
          );
        })}
      </section>

      <Toggle
        label={'Question Content Editor'}
        state={isQuestionContentEditor}
        setState={setQuestionContentEditor}
        style={'mt-6'}
      />

      <section className={`mt-2 ${!isQuestionContentEditor && 'hidden'}`}>
        <Editor
          onChange={contentInput.field.onChange}
          defaultMarkdownValue={question?.content || undefined}
        />
        <p className={'description'}>
          You can add images by dropping it inside the editor
        </p>
      </section>

      <section className={'mt-8'}>
        <h3 className={'mb-2 font-semibold'}>Question prompt*</h3>
        <Editor
          onChange={promptInput.field.onChange}
          defaultMarkdownValue={question?.prompt || undefined}
        />
        <p className={'description'}>
          You can add images by dropping it inside the editor
        </p>
      </section>

      <section className={'mt-4'}>
        <h3 className={'mr-2 font-semibold'}>Answer type</h3>
        <div className={'mt-2 grid grid-cols-2 gap-2'}>
          {answerTypes.map((type) => {
            return (
              <RadioButton
                key={type}
                value={type}
                label={names.answerType[type] || type}
                checked={answerType === type}
                disabled={false}
                register={register('answerType')}
              />
            );
          })}
        </div>
      </section>

      <section className={'mt-4'}>
        {answerType === QuestionAnswerType.MultipleChoice ? (
          <>
            <h3 className={'font-semibold'}>Choices*</h3>
            <div className={'flex justify-between'}>
              <AddHeader
                label={'Add Header'}
                state={addHeader}
                setState={setAddHeader}
                style={'mt-6'}
              />

              <AddColumn
                label={'Add Column'}
                setState={setAddColumn}
                style={'mt-6'}
              />
            </div>
            {addHeader && (
              <MultipleChoiceHeader
                answer={heading}
                numberOfColumns={addColumn}
                onChange={setHeading}
              />
            )}

            <MultipleChoices
              multipleAnswers={multipleAnswers}
              setMultipleAnswers={setMultipleAnswers}
              numberOfColumns={addColumn}
            />
            <Button
              onClick={() =>
                setMultipleAnswers((prevState) => [
                  ...prevState,
                  createNewMultipleAnswer(prevState.length),
                ])
              }
              white
              className={'w-full py-4'}
            >
              Add choice
            </Button>
          </>
        ) : answerType === QuestionAnswerType.TextEntry ? (
          <>
            <Editor
              onChange={answerInput.field.onChange}
              defaultMarkdownValue={
                question?.answerType === QuestionAnswerType.TextEntry
                  ? choices?.[0]?.text || undefined
                  : undefined
              }
            />
          </>
        ) : (
          <>
            <Editor
              onChange={answerInput.field.onChange}
              defaultMarkdownValue={
                question?.answerType === QuestionAnswerType.HandWritten
                  ? choices?.[0]?.text || undefined
                  : undefined
              }
            />
            <p className={'description'}>
              You can add images by dropping it inside the editor
            </p>
          </>
        )}
      </section>

      <section className={'mt-6'}>
        <h3 className={'font-semibold'}>QExplain</h3>
        <Editor
          onChange={explainInput.field.onChange}
          defaultMarkdownValue={question?.explanation || undefined}
        />
        <p className={'description'}>
          You can add images by dropping it inside the editor
        </p>
      </section>

      <TagsEditor tags={tags} setTags={setTags} />

      <Toggle
        label={'Advanced Options'}
        state={isAdvancedOptions}
        setState={setAdvancedOptions}
        style={'mt-6'}
      />

      {isAdvancedOptions && (
        <section className={'mt-4'}>
          <div className={'mt-2'}>
            <label className={'text-sm font-semibold'} htmlFor="q-source-type">
              QSourceType
            </label>
            <input
              id={'q-source-type'}
              type="text"
              className={'base-input'}
              {...register('sourceType')}
            />
          </div>
          <div className={'mt-2'}>
            <label className={'text-sm font-semibold'} htmlFor="q-source-id">
              QSourceID
            </label>
            <input
              id={'q-source-id'}
              className={'base-input'}
              {...register('sourceQuestionId')}
            />
          </div>
        </section>
      )}

      {!!question && (
        <section
          className={cn(
            'mt-8 grid gap-3',
            isDraft ? 'grid-cols-3' : 'grid-cols-2',
          )}
        >
          <Button onClick={() => setIsPreview(true)} white>
            Preview
          </Button>
          {isDraft && (
            <Button
              onClick={handleSubmit((data) =>
                onSubmit({ ...data, status: QuestionStatus.Draft }),
              )}
              white
              type={'submit'}
            >
              Save draft
            </Button>
          )}
          <Button
            onClick={handleSubmit((data) =>
              onSubmit({ ...data, status: QuestionStatus.Published }),
            )}
            disabled={createLoading || updateLoading}
            type={'submit'}
          >
            {isDraft ? 'Publish' : 'Save'}
          </Button>
        </section>
      )}
      {!question && (
        <section className={'mt-8 grid grid-cols-3 gap-3'}>
          <Button onClick={() => setIsPreview(true)} white>
            Preview
          </Button>
          <Button
            onClick={handleSubmit((data) =>
              onSubmit({ ...data, status: QuestionStatus.Draft }),
            )}
            disabled={createLoading || updateLoading}
            white
            type={'submit'}
          >
            Save draft
          </Button>
          <Button
            onClick={handleSubmit((data) =>
              onSubmit({ ...data, status: QuestionStatus.Published }),
            )}
            disabled={createLoading || updateLoading}
            type={'submit'}
          >
            Publish
          </Button>
        </section>
      )}

      <Modal
        title={'Question preview'}
        onClose={() => setIsPreview(false)}
        isOpen={isPreview}
        fullScreen
      >
        <QuestionContent
          addHeader={addHeader}
          heading={heading}
          numberOfColumns={addColumn}
          isQuestionContentEditor={isQuestionContentEditor}
          content={isQuestionContentEditor ? contentInput.field.value : null}
          explanation={explainInput.field.value}
          prompt={promptInput.field.value}
          isMark={false}
          isPreviewMode={true}
          choicesPreview={{
            type: answerType,
            choices:
              answerType === QuestionAnswerType.MultipleChoice
                ? multipleAnswers.map((choice) => choice.text || '')
                : [answer],
          }}
          isReportAvailable={false}
          isMath={satSectionId === 1}
        />
      </Modal>
    </form>
  );
};
export default CreateAPQuestionForm;
