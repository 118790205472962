import GlobalIcon from '../../../../assets/icons/global.svg?react';
import { WatermarkGlobalParamsProps } from './types';
import AddHeader from '../../../../components/common/addHeaderColumn';
import { useWatermarkGlobalParams } from './useWatermarkGlobalParams';
import Button from '../../../../components/common/button';

const WatermarkGlobalParams = ({
  params,
  setParams,
}: WatermarkGlobalParamsProps): JSX.Element => {
  const {
    onSubmit,
    updateParams,
    paramsBooleanValue,
    paramsNumberValue,
    isLoading,
  } = useWatermarkGlobalParams({
    params,
    setParams,
  });

  return (
    <div className="mb-10 mt-8">
      <hr className="my-2 opacity-10" />
      <h2 className="flex">
        <GlobalIcon className="mr-2" /> Global changing
      </h2>
      <p className="description mb-4 mt-2">
        These settings are visible to all users on the platform
      </p>
      <form>
        {paramsNumberValue.map(({ param, max, min }) => (
          <div key={param} className="mb-4">
            <label className="text-gray-700 font mb-2 flex justify-between text-sm">
              <span>
                {param} {params[param]}
              </span>
              <span className="text-xs opacity-80">max: {max}</span>
            </label>
            <input
              type="range"
              min={min}
              max={max}
              value={params[param] as number}
              onChange={(e) =>
                updateParams({ [param]: Number(e.target.value) })
              }
              className="w-full"
              step="1"
            />
            <input
              type="number"
              min={min}
              max={max}
              value={params[param] as number}
              onChange={(e) =>
                updateParams({ [param]: Number(e.target.value) })
              }
              className="border-gray-300 mt-2 rounded border px-2 py-1"
            />
            <hr className="my-2 opacity-20" />
          </div>
        ))}
      </form>

      <div
        className="flex justify-around rounded-md p-3"
        style={{
          border: '1px solid rgba(100,100,100)',
          backgroundColor: 'rgb(240, 240, 240)',
        }}
      >
        {paramsBooleanValue.map((param) => (
          <div className="flex justify-around">
            <AddHeader
              style={'flex'}
              state={params[param] as boolean}
              setState={() => {
                updateParams({ [param]: !params[param] });
              }}
              label={`Show ${param} ?`}
            />
          </div>
        ))}
      </div>

      <Button
        disabled={isLoading}
        onClick={() => {
          onSubmit();
        }}
        className={'ml-auto mr-auto mt-5'}
      >
        Edit watermark
      </Button>
    </div>
  );
};

export default WatermarkGlobalParams;
