import { useSearchParams } from 'react-router-dom';
import CreateAPQuestionForm from '../../../components/questions/create-question/create-ap-question-form.tsx';

const CreateAPQuestion = () => {
  const [params] = useSearchParams();

  return (
    <div className={'flex items-center justify-center'}>
      <div className={'w-[656px] max-w-[1000px]'}>
        <h1>Create AP question</h1>
        <p className={'description'}>The process of creating a question</p>
        <CreateAPQuestionForm params={params} />
      </div>
    </div>
  );
};
export default CreateAPQuestion;
