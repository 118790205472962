import { ReactNode } from 'react';
import NavbarLink from './navbar-link.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import {
  customTitles,
  navBarPermissions,
  navbarSections,
  userLinks,
} from './navbar-constants.tsx';

export interface ILinkItem {
  title: string;
  href: string;
  icon: ReactNode;
}

const LinksBox = () => {
  const { isStudent, userRole } = useGetMe();

  return (
    <div>
      <ul className={'mt-3'}>
        {userLinks.map((link, index) => {
          return (
            <li key={index} className={'flex'}>
              <NavbarLink link={link} />
            </li>
          );
        })}
      </ul>

      {!isStudent && (
        <div className={'mt-3'}>
          <p className={'ml-4 text-sm uppercase text-white opacity-50'}>
            {userRole} section
          </p>
          <ul className={'mt-3'}>
            {navBarPermissions[userRole].map((permission, index) => {
              const navBarSection = navbarSections[permission];

              const updatedLink: ILinkItem =
                userRole in customTitles
                  ? {
                      ...navBarSection,
                      ...customTitles[userRole][permission],
                    }
                  : navBarSection;

              return (
                <li key={index} className={'flex'}>
                  <NavbarLink link={updatedLink} />
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default LinksBox;
