import CheckmarkOutlineIcon from '../../../../assets/icons/checkmark-outline.svg?react';
import CheckmarkIcon from '../../../../assets/icons/checkmark.svg?react';
import DragIcon from '../../../../assets/icons/drag-and-drop.svg?react';
import RemoveIcon from '../../../../assets/icons/remove.svg?react';
import { Editor } from '../../../editor/editor.tsx';
import { useDragControls, Reorder } from 'framer-motion';
import { IMultipleAnswer } from '../create-question-form.tsx';
import { useEffect, useState } from 'react';
import Tooltip from '../../../common/tooltip.tsx';
import { orderToLetter } from '../../../../utils/common-utils.ts';
import { Editors } from '../../../editor/editors.tsx';

interface IMultipleChoice {
  answer: IMultipleAnswer;
  onRemove: (id: string) => void;
  onClickCorrect: (id: string) => void;
  onChange: (id: string, text: string) => void;
  index: number;
  numberOfColumns?: number;
}

const MultipleChoice = ({
  answer,
  onRemove,
  onChange,
  onClickCorrect,
  index,
  numberOfColumns,
}: IMultipleChoice) => {
  const [text, setText] = useState(answer.text);
  const controls = useDragControls();

  useEffect(() => {
    onChange(answer.id, text);
  }, [text]);

  return (
    <Reorder.Item
      dragControls={controls}
      dragListener={false}
      value={answer}
      className={'my-2 flex select-none items-center'}
    >
      <Tooltip tip={'Mark if this is the correct answer'}>
        <span
          className={'cursor-pointer'}
          onClick={() => onClickCorrect(answer.id)}
        >
          {answer.correct ? (
            <CheckmarkIcon fill={'#275AD3'} />
          ) : (
            <CheckmarkOutlineIcon stroke={'#6B7280'} />
          )}
        </span>
      </Tooltip>

      <div
        className={'ml-2 cursor-grab p-1'}
        onPointerDown={(event) => controls.start(event)}
      >
        <DragIcon />
      </div>
      <span className={'mx-2 font-semibold'}>{orderToLetter(index)}.</span>
      <Editors
        numberOfColumns={numberOfColumns}
        setText={setText}
        defaultMarkdownValue={answer.text}
        // className={'min-h-[50px]'}
      />
      <span
        className={'ml-2 cursor-pointer rounded p-1 hover:bg-light-gray'}
        onClick={() => onRemove(answer.id)}
      >
        <RemoveIcon stroke={'#F05252'} />
      </span>
    </Reorder.Item>
  );
};

export default MultipleChoice;
