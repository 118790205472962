import { TestSectionFragment } from '../../../apollo/__generated__/graphql.ts';
import Button from '../../common/button.tsx';
import QuestionIcon from '../../../assets/icons/section-question.svg?react';
import ModuleIcon from '../../../assets/icons/section-module.svg?react';
import {
  IQuestionsExportSettingsFormData,
  QuestionsExportSettingsForm,
} from '../../questions/questions-export-settings-form.tsx';
import { Popover } from '../../common/popover.tsx';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import Modal from '../../common/modal.tsx';
import RunScript from '../scripts/run-script.tsx';
import { createPdfExportUrl } from '../../../utils/common-utils.ts';
import ModuleItem from './module-item.tsx';
import { Transition } from '@headlessui/react';
import { useGetMe } from '../../../utils/hooks.ts';

interface ITestSectionItem {
  section: TestSectionFragment;
  testSlug?: string;
  testId?: number;
  selectedModules: number[];
  setSelectedModules: Dispatch<SetStateAction<number[]>>;
}
const TestSectionItem = ({
  section,
  testId,
  selectedModules,
  setSelectedModules,
}: ITestSectionItem) => {
  const [scriptType, setScriptType] = useState<string | null>(null);
  const [openModules, setOpenModules] = useState(false);
  const closeScriptModal = () => setScriptType(null);

  const { isAdmin } = useGetMe();

  const questionsAmount = section.modules.reduce(
    (acc, item) => acc + item.questions.length,
    0,
  );

  const onClickSection = () => {
    setOpenModules(!openModules);
  };

  const onExport = useCallback(
    (params: IQuestionsExportSettingsFormData) => {
      if (!testId) return null;
      const url = createPdfExportUrl('test', {
        id: testId.toString(),
        moduleIds: section.modules.map((module) => module.id).join(','),
        ...params,
      });
      window.open(url);
    },
    [testId, section],
  );

  const isHasPermissionForRunScripts = isAdmin;

  return (
    <>
      <div
        onClick={onClickSection}
        className={
          'flex cursor-pointer items-center justify-between rounded border border-table-border bg-[#FAFAFA] p-2'
        }
      >
        <div className={'text-sm'}>
          <p className={'font-medium capitalize text-blue'}>
            {section.section.name}
          </p>
          <div className={'mt-1 flex text-gray'}>
            <div className={'flex items-center text-xs'}>
              <span>
                <QuestionIcon />
              </span>
              <span className={'ml-2'}>{section.modules.length} Modules</span>
            </div>
            <div className={'ml-4 flex items-center text-xs'}>
              <span>
                <ModuleIcon />
              </span>
              <span className={'ml-2'}>{questionsAmount} Questions</span>
            </div>
          </div>
        </div>
        <div className={'flex'}>
          <Popover
            triggerComponent={
              <button
                onClick={(e) => e.stopPropagation()}
                className={
                  'mx-2 ml-2 box-border flex h-fit items-center justify-center rounded bg-blue p-2 text-sm font-medium text-white hover:bg-dark-blue'
                }
              >
                Export
              </button>
            }
            contentProps={{ className: 'w-80' }}
          >
            <div className="grid gap-4">
              <div className="space-y-2">
                <h4 className="font-medium leading-none">Export</h4>
                <p className={'description'}>Set the section export settings</p>
              </div>
              <QuestionsExportSettingsForm onSubmit={onExport} />
            </div>
          </Popover>
          {isHasPermissionForRunScripts && (
            <Button onClick={() => setScriptType(section.section.name)} white>
              Run Script
            </Button>
          )}
        </div>
      </div>
      <Transition show={openModules}>
        <div className={'mb-2 flex flex-col gap-1'}>
          {section.modules.map((module) => {
            return (
              <ModuleItem
                key={module.id}
                id={module.id}
                name={`Module - ${module.order + 1}`}
                level={module.level}
                questions={module.questions.length}
                link={`${section.id}/${module.id}`}
                selected={selectedModules}
                setSelected={setSelectedModules}
              />
            );
          })}
        </div>
      </Transition>

      <Modal
        title={'Run script'}
        onClose={closeScriptModal}
        isOpen={!!scriptType}
        description={`This script is going to be run on the ${section.section.name} section`}
      >
        <RunScript
          type={scriptType || ''}
          questionsAmount={questionsAmount}
          sectionId={section.id}
          closeModal={closeScriptModal}
        />
      </Modal>
    </>
  );
};
export default TestSectionItem;
