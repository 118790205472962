import Tooltip from './tooltip.tsx';
import { Link } from 'react-router-dom';
import EditIcon from '../../assets/icons/edit.svg?react';
import { useGetMe } from '../../utils/hooks.ts';
import { NavBarEnum } from '../../utils/enums.ts';

interface IIdCell {
  id: number;
  slug?: string;
  browser: NavBarEnum;
}

const IdCell = ({ id, slug, browser }: IIdCell) => {
  const { isAdmin, isTeacher, isStaff, isTA, isSales } = useGetMe();

  const isHasPermissionForEdit = () => {
    switch (browser) {
      case NavBarEnum.TESTS_BROWSER:
        return isAdmin || isTeacher || isTA;
      case NavBarEnum.QUIZZES_BROWSER:
        return isAdmin || isStaff || isTA || isTeacher;
      case NavBarEnum.COURSES_BROWSER:
        return isAdmin || isStaff || isSales || isTA || isTeacher;
      case NavBarEnum.ASSETS_BROWSER:
        return isAdmin;
      case NavBarEnum.USERS_BROWSER:
        return isAdmin || isStaff || isSales || isTA;
      case NavBarEnum.SCRIPTS_BROWSER:
        return isAdmin || isStaff || isTeacher;
      default:
        false;
    }
  };

  return (
    <div className={'flex w-20 items-center justify-between'}>
      <span>{id}</span>
      {isHasPermissionForEdit() && (
        <Tooltip tip={'Edit'}>
          <Link
            to={`${browser}/${slug || id}`}
            className={'cursor-pointer p-1'}
          >
            <EditIcon />
          </Link>
        </Tooltip>
      )}
    </div>
  );
};
export default IdCell;
