import { gql } from './__generated__';

export const REPORT_FRAGMENT = gql(/* GraphQL */ `
  fragment QuestionReport on QuestionReport {
    createdAt
    updatedAt
    createdBy {
      name
    }
    description
    id
    status
    errorType
    type {
      id
      name
    }
    subject {
      id
      name
    }
    question {
      id
      originalId
      section {
        id
        name
      }
    }
  }
`);

export const GET_REPORTS = gql(/* GraphQL */ `
  query getReports(
    $filter: QuestionReportFilterInput
    $paging: PagingInput
    $sort: QuestionReportSortInput
  ) {
    questionReport {
      getQuestionReports(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          ...QuestionReport
        }
      }
    }
  }
`);
export const CREATE_REPORT = gql(/* GraphQL */ `
  mutation createReport($input: CreateQuestionReportInput!) {
    questionReport {
      create(input: $input) {
        ...QuestionReport
      }
    }
  }
`);

export const UPDATE_REPORT = gql(/* GraphQL */ `
  mutation updateReport($input: UpdateQuestionReportInput!) {
    questionReport {
      update(input: $input) {
        ...QuestionReport
      }
    }
  }
`);
