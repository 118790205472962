import { Link } from 'react-router-dom';

interface IProps {
  id: number;
  title: string;
  completedAt: string;
  mathScore: number | undefined | null;
  rwScore: number | undefined | null;
  testAttemptId?: number;
  subject?: string;
  testType?: string;
}

const CompletedTestCard = ({
  title,
  completedAt,
  rwScore,
  mathScore,
  testAttemptId,
  subject,
  testType,
}: IProps) => {
  return (
    <Link to={'/review-test/' + testAttemptId}>
      <div
        className={
          'my-2 cursor-pointer rounded-xl transition-all hover:shadow-xl'
        }
      >
        <div
          className={
            'flex h-[4rem] items-center rounded-t-xl bg-midnight-blue text-white'
          }
        >
          <div className={'ml-3 flex items-center font-semibold'}>
            <span>{title}</span>
            <span
              className={'ml-2 rounded bg-blue px-1 py-0.5 text-xs text-white'}
            >
              Test
            </span>
            <span
              className={'ml-2 rounded bg-blue px-1 py-0.5 text-xs text-white'}
            >
              {testType}
            </span>
            <span
              className={'ml-2 rounded bg-blue px-1 py-0.5 text-xs text-white'}
            >
              {subject}
            </span>
          </div>
        </div>
        <div className={'rounded-b-xl border border-light-gray p-2'}>
          <table className="my-3 w-full table-auto text-right font-semibold">
            <thead>
              <tr>
                <th></th>
                <th>{rwScore ? 'R&W' : ''} </th>
                <th>{mathScore ? 'Math' : ''}</th>
              </tr>
            </thead>
            <tbody className={'text-2xl text-midnight-blue'}>
              <tr>
                <td className={'text-4xl font-semibold'}>
                  {rwScore && mathScore
                    ? rwScore + mathScore
                    : rwScore
                      ? rwScore
                      : mathScore}
                </td>
                <td>{rwScore}</td>
                <td>{mathScore}</td>
              </tr>
            </tbody>
          </table>

          <p className={'text-sm text-gray'}>Completed: {completedAt}</p>
        </div>
      </div>
    </Link>
  );
};
export default CompletedTestCard;
