import {
  CalculatorIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/solid';
import { useState } from 'react';
import Modal from '../../common/modal.tsx';
import QuestionReportForm from './question-report-form.tsx';
import ReferenceContent from './reference-content.tsx';
import DesmosCalculator from './desmos-calculator.tsx';

export interface IQuestionInfoData {
  title: string;
  value: string | number | null | undefined;
}
interface IProps {
  isMath: boolean;
  data?: IQuestionInfoData[];
  isReportAvailable: boolean;
  questionId?: number;
}

const QuestionToolbar = ({
  isMath,
  data,
  isReportAvailable,
  questionId,
}: IProps) => {
  const [isCalc, setIsCalc] = useState(false);
  const [isReport, setIsReport] = useState(false);
  const [isReference, setReference] = useState(false);
  const closeModals = () => {
    setReference(false);
    setIsReport(false);
    setIsCalc(false);
  };

  return (
    <section className={'flex items-center justify-between p-2'}>
      <div className={'flex-center text-center text-sm'}>
        {data?.map((item) => (
          <div className={'mx-1'} key={item.title}>
            <span className={'mr-1 font-semibold'}>{item.title}</span>
            <span>{item.value}</span>
          </div>
        ))}
      </div>

      <div className={'flex items-end'}>
        {isReportAvailable && (
          <button
            className="mx-2 cursor-pointer hover:text-yellow focus:border-none focus:outline-none"
            onClick={() => setIsReport(true)}
          >
            <div className="flex flex-col items-center justify-center">
              <ExclamationTriangleIcon className="h-7 w-7" />
              <div className="mt-0 text-xs font-medium">Report</div>
            </div>
          </button>
        )}

        {isMath && (
          <>
            <button
              className="mx-2 cursor-pointer hover:text-gray focus:border-none focus:outline-none"
              onClick={() => setIsCalc((prevState) => !prevState)}
            >
              <div className="flex flex-col items-center justify-center">
                <CalculatorIcon className="h-7 w-7" />
                <div className="mt-0 text-xs font-medium">Calc</div>
              </div>
            </button>

            <button
              className={
                'mx-2 flex flex-col items-center justify-center hover:text-green'
              }
              onClick={() => setReference(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 24 24"
                className="h-4 w-4"
              >
                <path
                  fillRule="evenodd"
                  d="M4.307 2.713A.75.75 0 015 2.25h14a.75.75 0 010 1.5H6.813l7.732 7.72a.75.75 0 010 1.06l-7.732 7.72H19a.75.75 0 010 1.5H5a.75.75 0 01-.53-1.28L12.954 12 4.47 3.53a.75.75 0 01-.163-.817z"
                />
              </svg>
              <div className="mt-2 text-xs font-medium">Reference</div>
            </button>
          </>
        )}
      </div>
      <Modal title={'Question Report'} onClose={closeModals} isOpen={isReport}>
        <QuestionReportForm questionId={questionId} close={closeModals} />
      </Modal>

      <Modal
        title={'Reference Sheet'}
        onClose={closeModals}
        isOpen={isReference}
      >
        <ReferenceContent />
      </Modal>

      <DesmosCalculator show={isCalc} onClose={closeModals} />
    </section>
  );
};
export default QuestionToolbar;
