import { QuestionReportSortInput } from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

export const initialReportsSort: ISortObj = {
  createdBy: undefined,
  description: undefined,
  errorType: undefined,
  questionId: undefined,
  status: undefined,
  subject: undefined,
  type: undefined,
};

export type IReportsSortTableKeys = keyof QuestionReportSortInput | 'link';
export const reportsSortKeys: IReportsSortTableKeys[] = [
  'errorType',
  'description',
  'createdBy',
  'questionId',
  'status',
  'questionSectionId',
  'createdAt',
  'updatedAt',
  'subjectId',
  'typeId',
];

type IColumn = {
  title: string;
  key: IReportsSortTableKeys;
};
export const reportsColumns: IColumn[] = [
  { key: 'errorType', title: 'Type' },
  { key: 'description', title: 'Description' },
  { key: 'createdBy', title: 'Sent by' },
  { key: 'questionId', title: 'QID' },
  { key: 'status', title: 'Status' },
  { key: 'questionSectionId', title: 'Category' },
  { key: 'createdAt', title: 'Date Submitted' },
  { key: 'updatedAt', title: 'Date Resolved' },
  { key: 'link', title: '' },
  { key: 'subjectId', title: 'Test Subject' },
  { key: 'typeId', title: 'Test Type' },
];
