import { NavLink } from 'react-router-dom';
import { ILinkItem } from './links-box.tsx';

interface INavbarLink {
  link: ILinkItem;
}

const NavbarLink = ({ link }: INavbarLink) => {
  return (
    <NavLink
      className={({ isActive }) => {
        return isActive
          ? 'mt-1 flex w-full rounded-lg bg-dark-blue p-2 py-3 text-white'
          : 'mt-1 flex w-full rounded p-2 py-3 text-white hover:text-light-gray';
      }}
      to={link.href}
    >
      {link.icon}
      {link.title}
    </NavLink>
  );
};
export default NavbarLink;
