import { useEffect, useState, SetStateAction, Dispatch } from 'react';
import { Editor } from './editor';
import { specialCharacter } from '../../utils/constants';

export function Editors({
  numberOfColumns = 1,
  setText,
  defaultMarkdownValue,
}: {
  numberOfColumns?: number;
  setText: Dispatch<SetStateAction<string>>;
  defaultMarkdownValue: string;
}) {
  const [value, setValue] = useState(
    defaultMarkdownValue
      ? Array(numberOfColumns)
          .fill(true)
          .map((el, index) => ({
            id: index,
            content:
              index < defaultMarkdownValue.split(specialCharacter).length
                ? defaultMarkdownValue.split(specialCharacter)[index]
                : '',
          }))
      : Array(numberOfColumns)
          .fill(true)
          .map((el, index) => ({ id: index, content: '' })),
  );

  useEffect(() => {
    setValue((prevState) => {
      if (prevState.length < numberOfColumns) {
        return [...prevState, { id: numberOfColumns - 1, content: '' }];
      } else if (prevState.length > numberOfColumns) {
        return prevState.filter((el) => el.id !== numberOfColumns);
      }
      return prevState;
    });
  }, [numberOfColumns, setValue]);

  useEffect(() => {
    const text = value
      .map((el: { id: number; content: string }) => {
        return el.content;
      })
      .join(':');
    setText(text);
  }, [setText, value]);

  const handleTextChange = (index: number, newText: string) => {
    return setValue((prevState) => {
      const newArr = prevState.map((el) => {
        if (index === el.id) {
          return {
            id: el.id,
            content: newText,
          };
        }
        return {
          id: el.id,
          content: el.content,
        };
      });
      return newArr;
    });
  };
  return (
    <div className={'flex gap-x-2'}>
      {Array(numberOfColumns)
        .fill(true)
        .map((_, index: number) => {
          return (
            <Editor
              key={index}
              onChange={(newText) => handleTextChange(index, newText)}
              defaultMarkdownValue={
                index < defaultMarkdownValue.split(specialCharacter).length
                  ? defaultMarkdownValue.split(specialCharacter)[index]
                  : ''
              }
              className={'min-h-[50px]'}
            />
          );
        })}
    </div>
  );
}
