import { Link } from 'react-router-dom';
import { cn } from '../../utils/common-utils.ts';
import { useGetMe } from '../../utils/hooks.ts';

export type ICardType = 'Test' | 'Quiz' | 'Course';
export interface ICard {
  title: string;
  type: ICardType;
  purchase?: boolean;
  description?: string;
  id: number;
  lastAttemptId?: number;
  completedAt?: string;
  archiveAt?: string | undefined; // for course
  createdAt?: Date;
  testType?: string;
  subject?: string;
  test?: {
    mathScore: number | null | undefined;
    rwScore: number | null | undefined;
    testAttemptId?: number;
  };
  quiz?: {
    score?: string;
    questionsData?: string;
  };
}

interface IProps {
  card: ICard;
}

const TaskCard = ({ card }: IProps) => {
  const { isStudent, user } = useGetMe();
  let gradient = '';
  let chip = null;

  switch (card.type) {
    case 'Course':
      gradient = `bg-gradient-to-tr from-[#45BDC7] to-[#312372]`;
      break;
    case 'Quiz':
      gradient = `bg-gradient-to-tr from-[#EC7B2D] to-[#D9402E]`;
      chip = (
        <>
          <span
            className={'ml-2 rounded bg-orange px-1 py-0.5 text-xs text-white'}
          >
            {card.type}
          </span>
          <span
            className={'ml-2 rounded bg-orange px-1 py-0.5 text-xs text-white'}
          >
            {card.testType}
          </span>
          <span
            className={'ml-2 rounded bg-orange px-1 py-0.5 text-xs text-white'}
          >
            {card.subject?.substring(0, 5) + '...'}
          </span>
        </>
      );
      break;
    case 'Test':
      gradient = `bg-gradient-to-tr from-[#45BDC7] to-[#312372]`;
      chip = (
        <>
          <span
            className={'ml-2 rounded bg-blue px-1 py-0.5 text-xs text-white'}
          >
            {card.type}
          </span>
          <span
            className={'ml-2 rounded bg-blue px-1 py-0.5 text-xs text-white'}
          >
            {card.testType}
          </span>
          <span
            className={'ml-2 rounded bg-blue px-1 py-0.5 text-xs text-white'}
          >
            {card.subject}
          </span>
        </>
      );
      break;
  }

  return (
    <Link
      to={
        card.type === 'Course'
          ? isStudent
            ? `/courses/${card.id}`
            : `/courses/${user?.id}/${card.id}`
          : card.type === 'Test'
            ? `/test-attempt/${card.lastAttemptId}`
            : `/quiz-attempt/${card.lastAttemptId}`
      }
      className={'my-2 flex flex-col rounded-xl transition-all hover:shadow-xl'}
    >
      <div className={cn('h-[8rem] rounded-t-xl', gradient)} />
      <div className={'flex-1 rounded-b-xl border border-light-gray px-3 py-2'}>
        <div className={`flex items-center font-semibold `}>
          <span>
            {card.title.length > 25
              ? card.title.substring(0, 24) + '...'
              : card.title}
          </span>
          {!!chip && <span>{chip}</span>}
        </div>
        <p className={'text-sm text-gray'}>{card.description}</p>
      </div>
    </Link>
  );
};
export default TaskCard;
