import { Link } from 'react-router-dom';
import LinksBox from './links-box.tsx';

interface INavigation {
  state: boolean;
}

const Navbar = ({ state }: INavigation) => {
  if (!state) return null;
  return (
    <div
      className={
        'flex h-[calc(100vh-64px)] w-[16rem] min-w-[16rem] flex-col overflow-auto bg-blue pb-12 lg:fixed lg:h-screen'
      }
    >
      <div className={'flex items-center justify-between px-3 pt-3'}>
        <Link to={'/'}>
          <img
            src={`${import.meta.env.VITE_UPLOAD_S3_HOST}/logo.png`}
            alt="logo"
          />
        </Link>
      </div>
      <nav className={'flex grow flex-col justify-between px-3'}>
        <LinksBox />
      </nav>
    </div>
  );
};
export default Navbar;
