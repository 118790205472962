import { IAssigmentCard } from '../components/tasks/assignment-card.tsx';
import { useGetMyAssignments } from '../components/users/users-hooks.tsx';
import Tabs from '../components/common/tabs.tsx';
import AssignmentsGroup from '../components/tasks/assignments-group.tsx';
import { getDateString } from '../utils/common-utils.ts';
import { maxBy } from 'lodash';
import {
  QuizAttemptStatus,
  QuizStatus,
  TestAttemptStatus,
  TestStatus,
  UserAssignmentStatus,
} from '../apollo/__generated__/graphql.ts';

const Assignments = () => {
  const { tests, quizzes, questions, answers } = useGetMyAssignments();
  const testsCards: IAssigmentCard[] = tests.map((el) => {
    const attempts = el.test?.attempts;
    const lastAttempt = maxBy(attempts, (el) => new Date(el.createdAt));
    const isCompleted = !!attempts?.some(
      (item) => item.status === TestAttemptStatus.Completed,
    );
    const isDraft = el.test?.status === TestStatus.Draft;
    const isArchive =
      el.type === 'assignment' && el.status === UserAssignmentStatus.Archive;

    return {
      type: 'Test',
      title: el.test?.name || '',
      id: el.test!.id,
      assignedOn: getDateString(el.createdAt) || '',
      assignedBy:
        el.type === 'purchase'
          ? el.user?.name || ''
          : el.createdBy?.name || '(Deleted user)',
      status:
        isDraft || isArchive
          ? 'Archived'
          : lastAttempt
            ? isCompleted
              ? 'Completed'
              : 'In progress'
            : 'Not started',
      lastAttemptStatus: lastAttempt?.status,
      testType: el.test?.type.name,
      subject: el.test?.subject.name,
      test: {
        slug: el.test?.slug || '',
        status: el.test?.status,
        lastAttemptId: lastAttempt?.id,
        scoreReportUrl: lastAttempt?.scoreReportUrl,
        answersReportUrl: lastAttempt?.answerReportUrl,
      },
    };
  });
  const quizzesCards: IAssigmentCard[] = quizzes.map((el) => {
    const attempts = el.quiz?.attempts;
    const lastAttempt = maxBy(
      attempts,
      (attempt) => new Date(attempt.createdAt),
    );
    const isCompleted = !!attempts?.some(
      (item) => item.status === QuizAttemptStatus.Completed,
    );
    const isDraft = el.quiz?.status === QuizStatus.Draft;
    const isArchive =
      el.type === 'assignment' && el.status === UserAssignmentStatus.Archive;

    return {
      type: 'Quiz',
      title: el.quiz?.name || '',
      id: el.quiz!.id,
      assignedOn: getDateString(el.createdAt) || '',
      assignedBy:
        el.type === 'purchase'
          ? el.user?.name || ''
          : el.createdBy?.name || '(Deleted user)',
      status:
        isDraft || isArchive
          ? 'Archived'
          : lastAttempt
            ? isCompleted
              ? 'Completed'
              : 'In progress'
            : 'Not started',
      lastAttemptStatus: lastAttempt?.status,
      testType: el.quiz?.type.name,
      subject: el.quiz?.subject.name,
      quiz: {
        slug: el.quiz?.slug || '',
        status: el.quiz?.status,
        lastAttemptId: lastAttempt?.id,
      },
    };
  });
  const questionsCards: IAssigmentCard[] = questions.map((el) => {
    const isComplete = answers?.some(
      (answer) => answer.question.id === el.question?.id,
    );
    return {
      type: 'Question',
      title: `${el.question?.originalId}, ${el.question?.section?.name}, ${el.question?.skill?.name}`,
      id: el.question!.id,
      assignedOn: getDateString(el.createdAt) || '',
      assignedBy: el.createdBy?.name || '(Deleted user)',
      status: isComplete ? 'Completed' : 'Not started',
    };
  });

  const assignmentsCount = quizzes.length + tests.length + questions.length;
  return (
    <div>
      {!assignmentsCount ? (
        <p className={'mt-20 text-center text-lg font-medium text-dark-blue'}>
          You don't have any assignments
        </p>
      ) : (
        <>
          <h1>
            Assignments
            {!!assignmentsCount && (
              <span className={'amount-indicator'}>
                ({quizzes.length + tests.length})
              </span>
            )}
          </h1>
          <Tabs
            tabs={[
              `All (${assignmentsCount})`,
              `Quizzes (${quizzes.length})`,
              `Tests (${tests.length})`,
              `Questions (${questions.length})`,
            ]}
            components={[
              <AssignmentsGroup
                assignments={[
                  ...questionsCards,
                  ...testsCards,
                  ...quizzesCards,
                ]}
              />,
              <AssignmentsGroup assignments={quizzesCards} />,
              <AssignmentsGroup assignments={testsCards} />,
              <AssignmentsGroup assignments={questionsCards} />,
            ]}
          />
        </>
      )}
    </div>
  );
};
export default Assignments;
