import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import { REMOVE_TESTS } from '../../apollo/tests.ts';
import { useState } from 'react';
import {
  CONTINUE_TEST_ATTEMPT,
  CREATE_TEST_ATTEMPT,
} from '../../apollo/test-attempt.ts';
import { TestAttemptMode } from '../../apollo/__generated__/graphql.ts';
import { useNavigate } from 'react-router-dom';
import { GET_MY_TEST_ATTEMPTS } from '../../apollo/user-assignments.ts';
import { createUserFeedbackEnvelope } from '@sentry/react';

export const useRemoveTests = (afterRemove?: () => void) => {
  const [isModalOpen, setModal] = useState(false);
  const [remove] = useMutation(REMOVE_TESTS);
  const onRemoveTests = (ids: number[]) => {
    if (!ids.length) return null;
    const promise = remove({
      variables: {
        input: {
          ids,
        },
      },
    });

    const text = ids.length > 1 ? 'Tests' : 'Test';
    toast.promise(promise, {
      pending: `Deleting ${text}...`,
      success: `${text} deleted`,
    });

    promise.then(() => {
      if (afterRemove) afterRemove();
    });
  };

  const onCloseModal = () => setModal(false);
  const onOpenModal = () => setModal(true);

  return {
    onRemoveTests,
    onCloseModal,
    onOpenModal,
    isModalOpen,
  };
};

export const useTestsSearch = () => {
  const initialFilter: { name?: string; typeId?: number; subjectId?: number } =
    {
      name: undefined,
      typeId: undefined,
      subjectId: undefined,
    };
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    if (type === 'name') setFilter({ name: value });
    if (type === 'subjectId') {
      setFilter({
        ...initialFilter,
        subjectId: value?.length ? Number(value) : undefined,
      });
    }
    if (type === 'typeId') {
      setFilter({
        ...initialFilter,
        typeId: value?.length ? Number(value) : undefined,
      });
    }
  };

  return { filter, onChangeFilter };
};

export const useStartNewTest = () => {
  const navigate = useNavigate();
  const [create] = useMutation(CREATE_TEST_ATTEMPT, {
    refetchQueries: [GET_MY_TEST_ATTEMPTS],
  });

  const startNewTest = (testId: number) => {
    create({
      variables: {
        input: { mode: TestAttemptMode.Practice, testId },
      },
    }).then((res) =>
      navigate(`/test-attempt/${res.data?.testAttempt.create.id}`),
    );
  };

  return { startNewTest };
};

export const useContinueTest = (testAttemptId?: number) => {
  const navigate = useNavigate();

  const [continueTestAttempt] = useMutation(CONTINUE_TEST_ATTEMPT);

  return () => {
    if (testAttemptId) {
      continueTestAttempt({
        variables: {
          input: { id: testAttemptId },
        },
      }).then((res) =>
        navigate(`/test-attempt/${res.data?.testAttempt.continue.id}`),
      );
    }
  };
};
