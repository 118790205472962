import { ILinkItem } from './links-box.tsx';
import CoursesIcon from '../../assets/icons/courses.svg?react';
import AssignmentsIcon from '../../assets/icons/assignments.svg?react';
import HomeIcon from '../../assets/icons/home.svg?react';
import TestsIcon from '../../assets/icons/tests.svg?react';
import QuizzesIcon from '../../assets/icons/quizzes.svg?react';
import FolderIcon from '../../assets/icons/folder.svg?react';
import UsersIcon from '../../assets/icons/two-user.svg?react';
import ReportIcon from '../../assets/icons/report.svg?react';
import WaterMarkIcon from '../../assets/icons/watermark.svg?react';
import QuestionIcon from '../../assets/icons/question.svg?react';
import { UserRole } from '../../apollo/__generated__/graphql.ts';
import { NavBarEnum } from '../../utils/enums.ts';
import {
  RoutesEnum as R,
  RoutesPermissionEnum as RP,
} from '../../utils/enums.ts';

export const userLinks: ILinkItem[] = [
  {
    title: 'Home',
    href: R.HOME,
    icon: <HomeIcon className={'mr-2'} />,
  },
  {
    title: 'Assignments',
    href: R.ASSIGNMENTS,
    icon: <AssignmentsIcon className={'mr-2'} />,
  },
  {
    title: 'Courses',
    href: R.COURSES,
    icon: <CoursesIcon className={'mr-2'} />,
  },
  {
    title: 'Tests',
    href: R.START_TEST,
    icon: <TestsIcon className={'mr-2'} />,
  },
  {
    title: 'Quizzes',
    href: R.START_QUIZ,
    icon: <QuizzesIcon className={'mr-2'} />,
  },
];

export const navbarSections: {
  [key: string]: ILinkItem;
} = {
  [NavBarEnum.SAT_QUESTIONS_BROWSER]: {
    title: 'SAT Questions Browser',
    href: RP.SAT_QUESTIONS_BROWSER,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.AP_QUESTIONS_BROWSER]: {
    title: 'AP Questions Browser',
    href: RP.AP_QUESTIONS_BROWSER,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.TESTS_BROWSER]: {
    title: 'Tests Browser',
    href: RP.TESTS_BROWSER,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.QUIZZES_BROWSER]: {
    title: 'Quizzes Browser',
    href: RP.QUIZZES_BROWSER,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.COURSES_BROWSER]: {
    title: 'Courses Browser',
    href: RP.COURSES_BROWSER,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.ASSETS_BROWSER]: {
    title: 'Assets Browser',
    href: RP.ASSETS_BROWSER,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.TEACHER_ASSETS]: {
    title: 'Assets',
    href: RP.ASSETS,
    icon: <FolderIcon className={'mr-2'} />,
  },
  [NavBarEnum.USERS_BROWSER]: {
    title: 'Users Browser',
    href: RP.USERS_BROWSER,
    icon: <UsersIcon className={'mr-2'} />,
  },
  [NavBarEnum.QUESTIONS_REPORTS]: {
    title: 'Questions Reports',
    href: RP.QUESTIONS_REPORTS,
    icon: <ReportIcon className={'mr-2'} />,
  },
  [NavBarEnum.ANSWERS_VIEW]: {
    title: 'Answers View',
    href: RP.ANSWERS_VIEW,
    icon: <QuestionIcon className={'mr-2'} />,
  },
  [NavBarEnum.WATERMARK]: {
    title: 'Watermark',
    href: RP.WATERMARK,
    icon: <WaterMarkIcon className={'mr-2'} />,
  },
};

export const customTitles: {
  [key: string]: { [key: string]: { title: string } };
} = {
  [UserRole.Teacher]: {
    [NavBarEnum.AP_QUESTIONS_BROWSER]: {
      title: 'My AP Questions',
    },
    [NavBarEnum.SAT_QUESTIONS_BROWSER]: {
      title: 'My SAT Questions',
    },
    [NavBarEnum.TESTS_BROWSER]: {
      title: 'My Tests',
    },
    [NavBarEnum.QUIZZES_BROWSER]: {
      title: 'My Quizzes',
    },
    [NavBarEnum.COURSES_BROWSER]: {
      title: 'My Courses',
    },
    [NavBarEnum.TEACHER_ASSETS]: {
      title: 'My Assets',
    },
    [NavBarEnum.USERS_BROWSER]: {
      title: 'Students Browser',
    },
    [NavBarEnum.ANSWERS_VIEW]: {
      title: 'Answers View',
    },
  },
};

export const navBarPermissions: { [key: string]: NavBarEnum[] } = {
  [UserRole.Admin]: Object.values(NavBarEnum).filter(
    (permission) =>
      ![NavBarEnum.TEACHER_ASSETS, NavBarEnum.SCRIPTS_BROWSER].includes(
        permission as NavBarEnum,
      ),
  ) as NavBarEnum[],
  [UserRole.Staff]: [
    NavBarEnum.AP_QUESTIONS_BROWSER,
    NavBarEnum.SAT_QUESTIONS_BROWSER,
    NavBarEnum.TESTS_BROWSER,
    NavBarEnum.QUIZZES_BROWSER,
    NavBarEnum.COURSES_BROWSER,
    NavBarEnum.USERS_BROWSER,
    NavBarEnum.QUESTIONS_REPORTS,
    NavBarEnum.ANSWERS_VIEW,
  ],
  [UserRole.Teacher]: [
    NavBarEnum.TEACHER_ASSETS,
    NavBarEnum.AP_QUESTIONS_BROWSER,
    NavBarEnum.SAT_QUESTIONS_BROWSER,
    NavBarEnum.TESTS_BROWSER,
    NavBarEnum.QUIZZES_BROWSER,
    NavBarEnum.COURSES_BROWSER,
    NavBarEnum.USERS_BROWSER,
    NavBarEnum.QUESTIONS_REPORTS,
    NavBarEnum.ANSWERS_VIEW,
  ],
  [UserRole.Ta]: [
    NavBarEnum.SAT_QUESTIONS_BROWSER,
    NavBarEnum.AP_QUESTIONS_BROWSER,
    NavBarEnum.TESTS_BROWSER,
    NavBarEnum.QUIZZES_BROWSER,
    NavBarEnum.COURSES_BROWSER,
    NavBarEnum.USERS_BROWSER,
    NavBarEnum.ANSWERS_VIEW,
  ],
  [UserRole.Sales]: [NavBarEnum.COURSES_BROWSER, NavBarEnum.USERS_BROWSER],
  [UserRole.Va]: [
    NavBarEnum.SAT_QUESTIONS_BROWSER,
    NavBarEnum.AP_QUESTIONS_BROWSER,
  ],
};
