import { Dispatch, SetStateAction } from 'react';
import { cn } from '../../utils/common-utils.ts';
import PlusIcon from '../../assets/icons/plus.svg?react';
import RemoveIcon from '../../assets/icons/remove.svg?react';
import Button from './button.tsx';

interface IProps {
  label: string;
  setState: Dispatch<SetStateAction<number>>;
  style?: string;
}
const AddColumn = ({ setState, style = '' }: IProps) => {
  return (
    <div className={cn('flex items-center gap-x-4', style)}>
      <>
        <Button
          className={'rounded-full'}
          white
          onClick={() =>
            setState((prevState) => (prevState > 1 ? prevState - 1 : prevState))
          }
          icon={
            <span className={'flex items-center gap-x-1'}>
              <RemoveIcon stroke={'#F05252'} />
              <span>Remove a Column</span>
            </span>
          }
        />
        <Button
          className={'rounded-full'}
          white
          onClick={() => setState((prevState) => prevState + 1)}
          icon={
            <span className={'flex items-center'}>
              <PlusIcon />
              <span>Add Column</span>
            </span>
          }
        />
      </>
    </div>
  );
};
export default AddColumn;
