import { useMutation } from '@apollo/client';
import { UPDATE_ME_WATERMARK } from '../../../../apollo/user';
import { toast } from 'react-toastify';
import { useGetMe } from '../../../../utils/hooks';

import { useState } from 'react';

export const useWatermarkLocalParams = () => {
  const { user } = useGetMe();

  const [value, setValue] = useState(user?.watermark ?? false);
  const [updateMe, mutateDate] = useMutation(UPDATE_ME_WATERMARK);

  const onSubmit = () => {
    const mutate = updateMe({
      variables: {
        input: {
          watermark: !value,
        },
      },
    });

    toast.promise(mutate, {
      pending: 'Updating',
      success: 'Your data has been successfully updated',
    });

    setValue(!value);
  };

  return {
    onSubmit,
    isLoading: mutateDate.loading,
    value,
  };
};
