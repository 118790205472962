import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSuspenseQuery } from '@apollo/client';
import { GET_ME } from '../apollo/user.ts';
import { GetMeQuery, UserRole } from '../apollo/__generated__/graphql.ts';
import { useInView } from 'react-intersection-observer';

export const useIsDesktop = () => {
  const breakpoint = 1024;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= breakpoint);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setIsDesktop(window.innerWidth >= breakpoint);
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return { isDesktop, windowWidth, setWindowWidth };
};

export const useControlMobileMenu = (
  isMenu: boolean,
  onCloseMenu: () => void,
) => {
  const location = useLocation();
  const { isDesktop } = useIsDesktop();

  useEffect(() => {
    if (isMenu && (isDesktop || location)) onCloseMenu();
  }, [isDesktop, location]);
};

interface IMeData {
  isStudent?: boolean;
  isAdmin?: boolean;
  isStaff?: boolean;
  isTeacher?: boolean;
  isSales?: boolean;
  isTA?: boolean;
  isVA?: boolean;
  user?: GetMeQuery['user']['me'];
  userRole: UserRole;
}
export const useGetMe = (): IMeData => {
  const { data } = useSuspenseQuery(GET_ME, { errorPolicy: 'all' });
  const user = data?.user.me;

  const userRole = user?.roles.length ? user?.roles[0] : UserRole.Student;

  const isAdmin = userRole === UserRole.Admin;
  const isStudent = userRole === UserRole.Student;
  const isStaff = userRole === UserRole.Staff;
  const isTeacher = userRole === UserRole.Teacher;
  const isSales = userRole === UserRole.Sales;
  const isTA = userRole === UserRole.Ta;
  const isVA = userRole === UserRole.Va;

  return {
    isAdmin,
    isStudent,
    isStaff,
    isTeacher,
    isSales,
    isTA,
    isVA,
    user,
    userRole,
  };
};

export const useDebounce = <T>(value: T, delay?: number): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 750);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useFetchMore = (
  onFetchMore: (setFetchMore: Dispatch<SetStateAction<boolean>>) => void,
  onChange?: (inView: boolean, entry: IntersectionObserverEntry) => void,
) => {
  const [isFetchMore, setFetchMore] = useState<boolean>(false);
  const { ref, inView, entry } = useInView({ delay: 100, onChange });

  useEffect(() => {
    if (inView && !isFetchMore) {
      onFetchMore(setFetchMore);
    }
  }, [inView, isFetchMore]);

  useEffect(() => {
    if (isFetchMore) {
      setFetchMore(false);
    }
  }, [entry?.target]);

  return { ref, isFetchMore, setFetchMore };
};

export const useDeleteItems = () => {
  const [isDeleted, setIsDeleted] = useState(false);
  const onDelete = (_: boolean, entry: IntersectionObserverEntry) => {
    if (isDeleted) {
      entry.target?.parentElement?.scrollIntoView();
      setIsDeleted(false);
    }
  };

  return { isDeleted, setIsDeleted, onDelete };
};

export const useSelectItem = (
  idList: number[],
  setSelectedAbove?: Dispatch<SetStateAction<number[]>>,
) => {
  const [selected, setSelected] = useState<number[]>([]);

  useEffect(() => {
    if (setSelectedAbove) setSelectedAbove(selected);
  }, [selected]);
  const onSelect = (id: number) => {
    const newState: number[] = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newState);
  };

  const onSelectAmount = (amount: number) => {
    const selected: number[] = [];
    idList?.forEach((q, index) => {
      if (index < amount) selected.push(q);
    });
    setSelected(selected);
  };
  const onResetAll = () => {
    setSelected([]);
  };

  return { selected, onSelectAmount, onSelect, onResetAll };
};
