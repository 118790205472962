import { useQuery, useSuspenseQuery } from '@apollo/client';
import { GET_REPORTS, REPORT_FRAGMENT } from '../apollo/reports.ts';
import { useFragment } from '../apollo/__generated__';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFetchMore } from '../utils/hooks.ts';
import {
  initialReportsSort,
  reportsColumns,
  reportsSortKeys,
} from '../components/reports/reports-constants.ts';
import {
  QuestionReportErrorType,
  QuestionReportStatus,
} from '../apollo/__generated__/graphql.ts';
import Table from '../components/table/table.tsx';
import _ from 'lodash';
import Select from '../components/common/select.tsx';
import {
  useReportsFilter,
  useUpdateReport,
} from '../components/reports/report-hooks.ts';
import Search from '../components/search.tsx';
import { cn, getDateString } from '../utils/common-utils.ts';
import { Link } from 'react-router-dom';
import Tag from '../components/common/tag.tsx';
import { useSort } from '../components/table/table-utils.ts';
import { GET_TEST_TYPES } from '../apollo/tests.ts';

const QuestionsReports = () => {
  const FETCH_LIMIT = 20;
  const [page, setPage] = useState<number>(1);
  const [sort, onSetSort] = useSort(initialReportsSort);
  const { filter, onChangeFilter } = useReportsFilter();
  const { onChangeStatus } = useUpdateReport();
  const { data, loading, fetchMore } = useQuery(GET_REPORTS, {
    fetchPolicy: 'network-only',
    variables: {
      sort,
      filter,
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
    },
  });
  const reports = useFragment(
    REPORT_FRAGMENT,
    data?.questionReport.getQuestionReports.results,
  );

  const { data: test_data } = useSuspenseQuery(GET_TEST_TYPES, {
    errorPolicy: 'all',
  });

  const testTypes: { key: string; title: string }[] =
    test_data?.test?.testTypes?.map((el) => {
      return { key: String(el.id), title: el.name };
    }) ?? [];

  const testSubjects: { key: string; title: string }[] = [];
  test_data?.test.testTypes.map((el) => {
    el.testSubjects?.map((el) => {
      testSubjects.push({ key: String(el.id), title: el.name });
    });
  });

  useEffect(() => {
    setPage(1);
  }, [sort, filter]);

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.questionReport.getQuestionReports.total);
    if (total && Number(reports?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.questionReport?.getQuestionReports?.results)
            return prev;
          const union = [
            ...(prev.questionReport?.getQuestionReports?.results || []),
            ...fetchMoreResult.questionReport.getQuestionReports.results,
          ];
          const uniqueReports = _.uniqBy(union, 'id');

          if (uniqueReports.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            questionReport: {
              ...prev.questionReport,
              getQuestionReports: {
                ...fetchMoreResult.questionReport.getQuestionReports,
                results: uniqueReports,
              },
            },
          };
        },
      });
    }
  };

  const { ref, isFetchMore } = useFetchMore(onFetchMore);
  const isLoading = loading || isFetchMore;

  return (
    <div>
      <div>
        <h1>Questions Reports</h1>
        <p className={'description'}>
          A list of all the reports submitted by users regarding a question
        </p>
      </div>

      <section>
        <div className={'mt-4'}>
          <div className={'flex items-center'}>
            <Search
              onChangeFilter={onChangeFilter}
              searchBy={[
                {
                  key: 'errorType',
                  title: 'Error Type',
                },
                'status',
                {
                  key: 'questionId',
                  title: 'QID',
                },
                {
                  key: 'questionSectionId',
                  title: 'Category',
                },
                { key: 'subjectId', title: 'subject' },
                { key: 'typeId', title: 'type' },
              ]}
              optionsData={{
                errorType: Object.values(QuestionReportErrorType),
                status: Object.values(QuestionReportStatus),
                questionSectionId: [
                  { key: '1', title: 'math' },
                  { key: '2', title: 'reading and writing' },
                ],
                subjectId: testSubjects,
                typeId: testTypes,
              }}
            />
            <div
              className={
                'ml-auto flex flex-col whitespace-nowrap text-sm text-gray'
              }
            >
              {!!reports && <p>{reports?.length} reports shown</p>}
            </div>
          </div>
          <hr className={'my-2 opacity-10'} />
        </div>
      </section>

      <Table
        columns={reportsColumns}
        isLoading={isLoading}
        sort={sort}
        setSort={onSetSort}
        sortKeys={reportsSortKeys}
        isEmpty={!reports}
      >
        {reports?.map((report, index, array) => {
          return (
            <tr key={report.id} ref={index === array.length - 5 ? ref : null}>
              <td>
                <Tag title={report.errorType} />
              </td>
              <td>
                <p className="text-wrap">{report.description}</p>
              </td>
              <td>{report.createdBy?.name || '(Deleted user)'}</td>
              <td>{report.question.originalId}</td>
              <td className={'!overflow-visible'}>
                <Select
                  value={report.status}
                  options={[
                    QuestionReportStatus.Pending,
                    QuestionReportStatus.Resolved,
                  ]}
                  onChange={(value) =>
                    onChangeStatus(report.id, value as QuestionReportStatus)
                  }
                  className={cn(
                    'text-md mx-1 w-28',
                    report.status === QuestionReportStatus.Resolved
                      ? 'bg-light-green text-green'
                      : 'bg-light-blue text-blue',
                  )}
                />
              </td>
              <td>{report.question.section?.name}</td>
              <td>{getDateString(report.createdAt)}</td>
              <td>
                {report.status === QuestionReportStatus.Resolved &&
                  getDateString(report.updatedAt)}
              </td>
              <td>
                <Link
                  to={`/edit-question/${report.question.id}`}
                  className={'link-white-button text-md'}
                >
                  Go to question
                </Link>
              </td>
              <td>{report.subject.name}</td>
              <td>{report.type.name}</td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
export default QuestionsReports;
