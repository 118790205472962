import { useEffect, useMemo, useState } from 'react';
import {
  EntityCreatedRole,
  InputMaybe,
  QuestionAnswerType,
  QuestionFilterInput,
  QuestionStatus,
  QuestionUsageType,
} from '../../../../apollo/__generated__/graphql.ts';
import { IFilter } from '../../../../routes/admin/questions/questions-browser-page.tsx';
import { v4 as uuidv4 } from 'uuid';
import { questionsBrowserFilters } from './filter-constants.ts';
import { useSuspenseQuery } from '@apollo/client';
import { GET_CREATE_QUESTIONS_DATA } from '../../../../apollo/questions.ts';
import { names } from '../../../../utils/constants.ts';

export interface IFilterType {
  title: string;
  value: keyof Omit<QuestionFilterInput, 'created'>;
  type: 'input' | 'select';
}

export interface IFiltersOptions {
  key: string;
  title: string;
}

export type IQuestionsSelectOptions = Record<
  keyof Omit<
    QuestionFilterInput,
    | 'content'
    | 'explanation'
    | 'originalId'
    | 'prompt'
    | 'sourceQuestion'
    | 'tags'
    | 'choices'
    | 'sourceType'
    | 'typeId'
  >,
  IFiltersOptions[]
>;
export const useGetOptionsForFilter = () => {
  const { data } = useSuspenseQuery(GET_CREATE_QUESTIONS_DATA, {
    errorPolicy: 'all',
  });
  const options: IQuestionsSelectOptions = useMemo(() => {
    return {
      answerType: [
        {
          key: QuestionAnswerType.MultipleChoice.toString(),
          title: names.answerType.SingleChoice,
        },
        {
          key: QuestionAnswerType.TextEntry.toString(),
          title: names.answerType.TextEntry,
        },
      ],
      difficulty:
        data?.question.difficulties.map((section) => {
          return { key: section.id.toString(), title: section.id.toString() };
        }) || [],
      domain:
        data?.question.domains.map((section) => {
          return { key: section.id.toString(), title: section.name };
        }) || [],
      section:
        data?.question.satSections.map((section) => {
          return { key: section.id.toString(), title: section.name };
        }) || [],
      skill:
        data?.question.skills.map((section) => {
          return { key: section.id.toString(), title: section.name };
        }) || [],
      status: [
        {
          key: QuestionStatus.Published.toString(),
          title: QuestionStatus.Published,
        },
        {
          key: QuestionStatus.Draft.toString(),
          title: QuestionStatus.Draft,
        },
      ],
      subject:
        data?.question.subjects.map((section) => ({
          key: section.id.toString(),
          title: section.name,
        })) || [],
      type:
        data?.question.types.map((section) => ({
          key: section.id.toString(),
          title: section.name,
        })) || [],
      usageType: [
        {
          key: QuestionUsageType.Copy.toString(),
          title: QuestionUsageType.Copy,
        },
        {
          key: QuestionUsageType.Mock.toString(),
          title: QuestionUsageType.Mock,
        },
        {
          key: QuestionUsageType.Quiz.toString(),
          title: QuestionUsageType.Quiz,
        },
        {
          key: QuestionUsageType.Real.toString(),
          title: QuestionUsageType.Real,
        },
      ],
      created: [
        {
          key: EntityCreatedRole.Nalaprep.toString(),
          title: EntityCreatedRole.Nalaprep,
        },
        {
          key: EntityCreatedRole.Teacher.toString(),
          title: EntityCreatedRole.Teacher,
        },
      ],
    };
  }, []);
  return options;
};

const createFilter = (current?: IFilter[]): IFilter => {
  let newFilter: IFilter = {
    data: questionsBrowserFilters[0],
    inputValue: '', // input or select value
    id: uuidv4(),
  };
  questionsBrowserFilters.some((filter) => {
    const isFilterUnused = current?.find((f) => f.data.value === filter.value);
    if (!isFilterUnused) {
      newFilter = {
        data: filter,
        inputValue: '',
        id: uuidv4(),
      };
      return true;
    }
  });
  return newFilter;
};

const createFilterHash = (filters: IFilter[]): string => {
  return filters.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.inputValue;
  }, '');
};

export const useQuestionFilter = (
  resetTable: () => void,
  initialFilterState: Omit<QuestionFilterInput, 'created'>,
) => {
  const [filterInput, setFilterInput] =
    useState<QuestionFilterInput>(initialFilterState);
  const [filters, setFilters] = useState<IFilter[]>([createFilter()]);
  const [filterHash, setFilterHash] = useState<string>('');

  useEffect(() => {
    const hash = createFilterHash(filters);
    if (hash !== filterHash) {
      const filter = generateFilterObj(filters, initialFilterState);
      setFilterHash(hash);
      resetTable();
      setFilterInput(filter);
    }
  }, [filters]);

  const onAddFilter = () =>
    setFilters((prev) => [...prev, createFilter(filters)]);
  const onRemoveFilter = (idToRemove: string) => {
    setFilters((prevFilters) => {
      return prevFilters.filter((f) => f.id !== idToRemove);
    });
  };
  const onClearFilter = () => {
    setFilters([createFilter()]);
  };

  return {
    filterInput,
    filters,
    setFilters,
    onAddFilter,
    onRemoveFilter,
    onClearFilter,
  };
};

const generateFilterObj = (
  filters: IFilter[],
  initialFilterState: QuestionFilterInput,
): QuestionFilterInput => {
  const filterObject = { ...initialFilterState };
  filters.forEach((filter) => {
    const { value } = filter.data;
    const inputValue = filter.inputValue;

    switch (value) {
      case 'answerType':
        if (
          Object.values(QuestionAnswerType).includes(
            inputValue as QuestionAnswerType,
          )
        ) {
          filterObject[value] = getFilterValue(
            inputValue as QuestionAnswerType,
            filterObject[value],
          );
        }
        break;
      case 'tags':
        filterObject[value] && filterObject[value]?.name
          ? filterObject[value]?.name?.push(inputValue)
          : (filterObject[value] = { name: [inputValue] });
        break;
      case 'status':
        if (
          Object.values(QuestionStatus).includes(inputValue as QuestionStatus)
        ) {
          filterObject[value] = getFilterValue(
            inputValue as QuestionStatus,
            filterObject[value],
          );
        }
        break;
      case 'usageType':
        if (
          Object.values(QuestionUsageType).includes(
            inputValue as QuestionUsageType,
          )
        ) {
          filterObject[value] = getFilterValue(
            inputValue as QuestionUsageType,
            filterObject[value],
          );
        }
        break;
      case 'sourceQuestion':
        if (inputValue) {
          filterObject[value] && filterObject[value]?.originalId
            ? filterObject[value]?.originalId?.push(Number(inputValue))
            : (filterObject[value] = { originalId: [Number(inputValue)] });
        }
        break;
      case 'originalId':
        filterObject[value] = inputValue;
        break;
      case 'difficulty':
      case 'domain':
      case 'section':
      case 'skill':
      case 'subject':
      case 'type':
        filterObject[value] && filterObject[value]?.id
          ? filterObject[value]?.id?.push(Number(inputValue))
          : (filterObject[value] = { id: [Number(inputValue)] });
        break;
      default:
        if (inputValue) {
          // todo: fix typings
          filterObject[value] && Array.isArray(filterObject[value])
            ? (filterObject[value] as Array<string>)?.push(inputValue)
            : ((filterObject[value] as Array<string>) = [inputValue]);
        }
        break;
    }
  });

  return filterObject;
};

const getFilterValue = <T>(inputValue: T, value?: InputMaybe<T[]>) => {
  let newValue = value;
  value?.length ? newValue?.push(inputValue) : (newValue = [inputValue]);
  return newValue;
};
