import StarsIcon from '../../assets/icons/stars.svg?react';
import Button from '../../components/common/button.tsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { generateSlug } from 'random-word-slugs';
import {
  Maybe,
  QuizFragmentFragment,
  QuizStatus,
} from '../../apollo/__generated__/graphql.ts';
import { skipToken, useMutation, useSuspenseQuery } from '@apollo/client';
import {
  CREATE_QUIZ,
  QUIZ_FRAGMENT,
  UPDATE_QUIZ,
} from '../../apollo/quizzes.ts';
import { toast } from 'react-toastify';
import { useFragment } from '../../apollo/__generated__';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { compact } from 'lodash';
import { GET_TEST_TYPES } from '../../apollo/tests.ts';

interface IForm {
  name: string;
  description: string;
  typeId: number;
  subjectId: number;
}

interface ICreateQuiz {
  quiz?: Maybe<QuizFragmentFragment>;
  onCloseModal?: (slug: string) => void;
  questions?: number[];
}

const CreateQuizForm = ({ quiz, onCloseModal, questions }: ICreateQuiz) => {
  const navigate = useNavigate();
  const [createQuiz, { data: newQuizData }] = useMutation(CREATE_QUIZ);
  const [updateQuiz] = useMutation(UPDATE_QUIZ);
  const newQuiz = useFragment(QUIZ_FRAGMENT, newQuizData?.quiz.create);

  const { data: test_data } = useSuspenseQuery(
    GET_TEST_TYPES,
    { errorPolicy: 'all' },
    // quiz ? skipToken : { errorPolicy: 'all' }, //should we remove skipToken as it causes data to not be fetched test_data remains undefined
  );
  const [testSubjects, setTestSubjects] = useState(
    quiz
      ? test_data?.test.testTypes.find((type) => type.id === quiz.type.id)
          ?.testSubjects
      : test_data?.test.testTypes[0].testSubjects,
  );

  useEffect(() => {
    const slug = newQuiz?.slug;
    if (slug) navigate(`/quizzes-browser/${slug}`);
  }, [navigate, newQuiz]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<IForm>({
    defaultValues: {
      name: quiz?.name ? quiz.name : undefined,
      description: quiz?.description ? quiz.description : undefined,
      subjectId: quiz?.subject.id,
      typeId: quiz?.type.id,
    },
  });

  const onCreate: SubmitHandler<IForm> = (data) => {
    const create = createQuiz({
      variables: {
        input: {
          name: data.name,
          description: data.description,
          orderedQuestions:
            questions?.map((id, index) => ({
              questionId: id,
              order: index,
            })) || [],
          status: QuizStatus.Draft,
          typeId: Number(data.typeId),
          subjectId: Number(data.subjectId),
        },
      },
    });

    toast.promise(create, {
      pending: 'Creating quiz...',
      success: 'Quiz created',
    });
  };

  const onUpdate: SubmitHandler<IForm> = (data) => {
    if (!quiz?.id) return;
    const currentQuestionsIds =
      quiz.quizQuestions?.map(({ question }) => question?.id) || [];
    const questionIds: number[] = compact(currentQuestionsIds);
    const update = updateQuiz({
      variables: {
        input: {
          id: quiz.id,
          name: data.name,
          description: data.description,
          status: quiz.status,
          orderedQuestions:
            questionIds?.map((id, index) => ({
              questionId: id,
              order: index,
            })) || [],
          typeId: Number(data.typeId),
          subjectId: Number(data.subjectId),
        },
      },
    });

    toast.promise(update, {
      pending: 'Updating quiz...',
      success: 'Quiz updated',
    });

    update.then((data) => {
      const slug = data.data?.quiz.update.slug;
      if (onCloseModal && slug) onCloseModal(slug);
    });
  };

  const onClickGenerateName = () =>
    setValue('name', generateSlug(3, { format: 'title' }));

  const handleTestTypeChange = (id: number) => {
    const selectedTestType = test_data?.test.testTypes.find(
      (type) => type.id === id,
    );
    setTestSubjects(selectedTestType?.testSubjects || []);
  };

  return (
    <form className={'w-[500px] p-4'} onSubmit={handleSubmit(onCreate)}>
      <div className={'mt-8'}>
        <label htmlFor="name" className={'font-medium'}>
          Name
        </label>
        <div className={'flex items-center'}>
          <input
            type="text"
            placeholder={'e.g. Math Quiz'}
            className={'base-input'}
            {...register('name', { required: true })}
          />
          <Button
            icon={<StarsIcon />}
            white
            tip={'Generate unique name'}
            className={'ml-2'}
            onClick={onClickGenerateName}
          />
        </div>
        {errors.name && (
          <span className={'text-sm text-red'}>This field is required</span>
        )}
        <p className={'description'}>The quiz name must be unique</p>
      </div>

      <div className={'mt-8'}>
        <label htmlFor="name" className={'font-medium'}>
          Test Type
        </label>
        <div className={'flex items-center'}>
          <select
            id="test-subject"
            className={
              'block h-10 w-full rounded-lg border border-light-gray pl-2'
            }
            {...register('typeId', { required: true })}
            onChange={(e) => {
              handleTestTypeChange(Number(e.target.value));
            }}
            defaultValue={test_data?.test.testTypes[0].id}
          >
            <option value="">Select a Test Type</option>
            {test_data?.test.testTypes.map((type) => (
              <option key={type.name} value={type.id}>
                {type.name}
              </option>
            ))}
          </select>
        </div>
        {errors.typeId && (
          <span className={'text-sm text-red'}>This field is required</span>
        )}
      </div>
      <div className={'mt-8'}>
        <label htmlFor="name" className={'font-medium'}>
          Test Subject
        </label>
        <div className={'flex items-center'}>
          <select
            id="test-subject"
            className={
              'block h-10 w-full rounded-lg border border-light-gray pl-2'
            }
            {...register('subjectId', { required: true })}
            // defaultValue={testSubjects[0].name}
          >
            <option value="">Select a Test Subject</option>
            {testSubjects?.map((sub) => (
              <option key={sub.name} value={Number(sub.id)}>
                {sub.name}
              </option>
            ))}
          </select>
        </div>
        {errors.typeId && (
          <span className={'text-sm text-red'}>This field is required</span>
        )}
      </div>
      <div className={'mt-6 flex flex-col'}>
        <label htmlFor="description" className={'font-medium'}>
          Description
        </label>
        <textarea
          placeholder={'Enter Description...'}
          className={'h-32 max-h-80 rounded-lg border border-light-gray p-1'}
          {...register('description', { required: true })}
        />
        {errors.description && (
          <span className={'text-sm text-red'}>This field is required</span>
        )}
      </div>

      <Button
        type={'button'}
        onClick={quiz ? handleSubmit(onUpdate) : handleSubmit(onCreate)}
        className={'mt-8 w-full'}
      >
        {quiz ? 'Save' : 'Create'}
      </Button>
    </form>
  );
};
export default CreateQuizForm;
