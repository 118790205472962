import {
  QuizFragmentFragment,
  QuizSortInput,
} from '../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../table/table-utils.ts';

type ISpecialKeys = '_checkbox';
export type IQuizTableKeys =
  | keyof Pick<
      QuizFragmentFragment,
      | 'id'
      | 'name'
      | 'description'
      | 'quizQuestions'
      | 'status'
      | 'created'
      | 'subject'
      | 'type'
    >
  | ISpecialKeys;
export type IQuizzesTableKeys = keyof QuizSortInput;
export const quizzesSortKeys: IQuizzesTableKeys[] = [
  'description',
  'id',
  'name',
  'status',
  'created',
  'subject',
  'type',
];

type IColumn = {
  title: string;
  key: IQuizTableKeys;
};
export const quizzesColumns: IColumn[] = [
  { key: '_checkbox', title: '' },
  { key: 'id', title: 'Id' },
  { key: 'created', title: 'Created' },
  { key: 'name', title: 'Name' },
  { key: 'description', title: 'Description' },
  { key: 'quizQuestions', title: 'Linked Questions' },
  { key: 'status', title: 'Status' },
  { key: 'subject', title: 'Test Subject' },
  { key: 'type', title: 'Test Type' },
];

export const initialQuizzesSort: ISortObj = {
  description: undefined,
  id: undefined,
  name: undefined,
  questionCount: undefined,
  status: undefined,
  subject: undefined,
  type: undefined,
};
