import { useMutation } from '@apollo/client';
import { UPDATE_REPORT } from '../../apollo/reports.ts';
import {
  QuestionReportErrorType,
  QuestionReportFilterInput,
  QuestionReportStatus,
} from '../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';
import { useState } from 'react';

export const useUpdateReport = () => {
  const [update] = useMutation(UPDATE_REPORT);

  const onChangeStatus = (id: number, status: QuestionReportStatus) => {
    const promise = update({
      variables: { input: { id, status } },
    });
    toast.promise(promise, {
      pending: 'Changing status...',
    });
  };

  return { onChangeStatus };
};

export const useReportsFilter = () => {
  const initialFilter: QuestionReportFilterInput = {
    errorType: undefined,
    status: undefined,
    questionId: undefined,
    questionSectionId: undefined,
    subjectId: undefined,
    typeId: undefined,
  };
  const [filter, setFilter] = useState(initialFilter);
  const onChangeFilter = (value?: string, type?: string) => {
    const input = value ? value : undefined;
    if (type === 'errorType')
      setFilter({
        ...initialFilter,
        errorType: input as QuestionReportErrorType,
      });
    if (type === 'status')
      setFilter({ ...initialFilter, status: input as QuestionReportStatus });
    if (type === 'questionId')
      setFilter({
        ...initialFilter,
        questionId: value ? Number(value) : undefined,
      });
    if (type === 'questionSectionId')
      setFilter({ ...initialFilter, questionSectionId: Number(input) });
    if (type === 'subjectId') {
      setFilter({
        ...initialFilter,
        subjectId: value?.length ? Number(value) : undefined,
      });
    }
    if (type === 'typeId') {
      setFilter({
        ...initialFilter,
        typeId: value?.length ? Number(value) : undefined,
      });
    }
  };

  return { filter, onChangeFilter };
};
