import { Link } from 'react-router-dom';
import {
  useDeleteItems,
  useFetchMore,
  useSelectItem,
} from '../../../utils/hooks.ts';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useSort } from '../../../components/table/table-utils.ts';
import { useMutation, useQuery } from '@apollo/client';
import { useFragment } from '../../../apollo/__generated__';
import { uniqBy } from 'lodash';
import {
  ASSET_TABLE_FRAGMENT,
  GET_ASSETS,
  REMOVE_ASSETS,
} from '../../../apollo/assets.ts';
import Table from '../../../components/table/table.tsx';
import IdCell from '../../../components/common/id-cell.tsx';
import {
  assetsColumns,
  assetsSortKeys,
  initialAssetsSort,
} from '../../../components/assets/assets-constants.ts';
import Search from '../../../components/search.tsx';
import { useAssetsSearch } from '../../../components/assets/assets-hooks.ts';
import { AssetStatus } from '../../../apollo/__generated__/graphql.ts';
import ActionsDropdown from '../../../components/table/actions-dropdown.tsx';
import { getDateString } from '../../../utils/common-utils.ts';
import { NavBarEnum } from '../../../utils/enums.ts';

const AssetsBrowser = () => {
  const FETCH_LIMIT = 20;
  const [page, setPage] = useState<number>(1);
  const [sort, onSetSort] = useSort(initialAssetsSort);
  const { filter, onChangeFilter } = useAssetsSearch();
  const { setIsDeleted, onDelete } = useDeleteItems();
  const { data, fetchMore, loading } = useQuery(GET_ASSETS, {
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter,
    },
  });
  const assets = useFragment(
    ASSET_TABLE_FRAGMENT,
    data?.asset.getAssets.results,
  );
  const { selected, onSelect, onSelectAmount, onResetAll } = useSelectItem(
    assets?.map((asset) => asset.id) || [],
  );
  const [remove] = useMutation(REMOVE_ASSETS, {
    refetchQueries: [GET_ASSETS],
  });
  const onRemoveAssets = (ids: number[]) => {
    remove({
      variables: {
        input: {
          ids,
        },
      },
    }).then(() => {
      onResetAll();
      setPage(1);
      setIsDeleted(true);
    });
  };

  useEffect(() => {
    setPage(1);
  }, [filter, sort]);

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.asset.getAssets.total);
    if (total && Number(assets?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.asset?.getAssets?.results) return prev;
          const union = [
            ...(prev.asset?.getAssets?.results || []),
            ...fetchMoreResult.asset.getAssets.results,
          ];
          const unique = uniqBy(union, 'id');

          if (unique.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            asset: {
              ...prev.asset,
              getAssets: {
                ...fetchMoreResult.asset.getAssets,
                results: unique,
              },
            },
          };
        },
      });
    }
  };

  const { ref, isFetchMore } = useFetchMore(onFetchMore, onDelete);
  const isLoading = loading || isFetchMore;

  return (
    <div>
      <section className={'flex items-center justify-between'}>
        <div>
          <h1>Assets Browser</h1>
          <p className={'description'}>
            A list of all the assets in the database
          </p>
        </div>
        <div className={'flex gap-2'}>
          <ActionsDropdown
            selected={selected}
            browser={NavBarEnum.ASSETS_BROWSER}
            onRemove={onRemoveAssets}
          />
          <Link to={'/create-asset'} className={'link-blue-button'}>
            Create Asset
          </Link>
        </div>
      </section>

      <div className={'mt-4'}>
        <Search
          onChangeFilter={onChangeFilter}
          searchBy={['name', 'description', 'status', 'id']}
          optionsData={{
            status: [
              { key: AssetStatus.Paid, title: AssetStatus.Paid },
              { key: AssetStatus.Free, title: AssetStatus.Free },
            ],
          }}
        />
      </div>

      <hr className={'my-2 opacity-10'} />

      <Table
        sort={sort}
        setSort={onSetSort}
        isLoading={isLoading}
        sortKeys={assetsSortKeys}
        columns={assetsColumns}
        selected={selected}
        onSelectAmount={onSelectAmount}
        onResetAll={onResetAll}
        isEmpty={!assets?.length}
      >
        {assets?.map((asset, index, array) => {
          return (
            <tr key={asset.id} ref={index === array.length - 5 ? ref : null}>
              <td>
                <div className={'flex justify-center'}>
                  <input
                    type={'checkbox'}
                    checked={selected.includes(asset.id)}
                    onChange={() => onSelect(asset.id)}
                  />
                </div>
              </td>
              <td>
                <IdCell id={asset.id} browser={NavBarEnum.ASSETS_BROWSER} />
              </td>
              <td>{asset.name}</td>
              <td>{asset.description}</td>
              <td>{asset.createdBy?.name || '(Deleted user)'}</td>
              <td>{asset.status}</td>
              <td>{asset.elements?.length ?? 0}</td>
              <td>{asset.purchases?.length ?? 0}</td>
              <td>{getDateString(asset.createdAt)}</td>
            </tr>
          );
        })}
      </Table>
    </div>
  );
};
export default AssetsBrowser;
