import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_USERS, USER_TABLE_FRAGMENT } from '../../../apollo/user.ts';
import { useFragment } from '../../../apollo/__generated__';
import { useFetchMore, useGetMe, useSelectItem } from '../../../utils/hooks.ts';
import Table from '../../../components/table/table.tsx';
import IdCell from '../../../components/common/id-cell.tsx';
import { useUsersSearch } from '../../../components/users/users-hooks.tsx';
import {
  usersColumns,
  usersColumnsModal,
  usersDefaultSort,
  usersSortKeys,
} from '../../../components/users/users-constants.ts';
import { uniqBy } from 'lodash';
import Search from '../../../components/search.tsx';
import Modal from '../../../components/common/modal.tsx';
import CreateUserForm from '../../../components/users/create-user-form.tsx';
import Button from '../../../components/common/button.tsx';
import { UserRole } from '../../../apollo/__generated__/graphql.ts';
import { useSort } from '../../../components/table/table-utils.ts';
import { NavBarEnum } from '../../../utils/enums.ts';

interface IProps {
  isModalMode?: boolean;
  defaultFilter?: { [key: string]: string };
  setSelectedUsers?: Dispatch<SetStateAction<number[]>>;
}
const UsersBrowser = ({
  isModalMode = false,
  defaultFilter,
  setSelectedUsers,
}: IProps) => {
  const FETCH_LIMIT = 20;
  const me = useGetMe();
  const [page, setPage] = useState<number>(1);
  const [createUser, setCreateUser] = useState(false);
  const onCloseCreateUser = () => setCreateUser(false);

  const [sort, onSetSort] = useSort(usersDefaultSort);
  const { filter, onChangeFilter } = useUsersSearch();
  const { data, fetchMore, loading } = useQuery(GET_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      paging: {
        page: 1,
        limit: FETCH_LIMIT,
      },
      sort,
      filter: { ...defaultFilter, ...filter },
    },
  });

  const users = useFragment(USER_TABLE_FRAGMENT, data?.user.getUsers.results);
  const { selected, onSelect, onSelectAmount, onResetAll } = useSelectItem(
    users?.map((user) => user.id) || [],
    setSelectedUsers,
  );

  useEffect(() => {
    setPage(1);
  }, [filter, sort]);

  const onFetchMore = (setFetchMore: Dispatch<SetStateAction<boolean>>) => {
    const total = Number(data?.user.getUsers.total);
    if (total && Number(users?.length) < total) {
      const nextPage = page + 1;
      setPage(nextPage);
      setFetchMore(true);
      fetchMore({
        variables: {
          paging: {
            page: nextPage,
            limit: FETCH_LIMIT,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult?.user.getUsers.results) return prev;
          const union = [
            ...(prev.user?.getUsers?.results || []),
            ...fetchMoreResult.user.getUsers.results,
          ];
          const unique = uniqBy(union, 'id');

          if (unique.length !== union.length)
            console.error('duplicates on merge detected'); // TODO remove after dev stage

          return {
            user: {
              ...prev.user,
              getUsers: {
                ...fetchMoreResult.user.getUsers,
                results: unique,
              },
            },
          };
        },
      });
    }
  };

  const { ref, isFetchMore } = useFetchMore(onFetchMore);
  const isLoading = isFetchMore || loading;

  return (
    <div>
      {!isModalMode && (
        <section className={'flex items-center justify-between'}>
          <div>
            <h1>{me.isTeacher ? 'Students Browser' : 'Users Browser'}</h1>
            <p className={'description'}>A list of all users in the database</p>
          </div>
          {!me.isTeacher && (
            <div className={'flex'}>
              <Button onClick={() => setCreateUser(true)}>Create user</Button>
            </div>
          )}
        </section>
      )}

      <div className={'mt-4'}>
        <Search
          onChangeFilter={onChangeFilter}
          searchBy={['name', 'email', 'schoolName']}
        />
      </div>

      <hr className={'my-2 opacity-10'} />

      <Table
        selected={selected}
        onSelectAmount={onSelectAmount}
        onResetAll={onResetAll}
        isLoading={isLoading}
        columns={setSelectedUsers ? usersColumnsModal : usersColumns}
        sort={sort}
        sortKeys={usersSortKeys}
        setSort={onSetSort}
        isEmpty={!users?.length}
      >
        {users?.map((user, index, array) => {
          return (
            <tr key={user.id} ref={index === array.length - 5 ? ref : null}>
              {!!setSelectedUsers && (
                <td>
                  <div className={'flex justify-center'}>
                    <input
                      type={'checkbox'}
                      checked={selected.includes(user.id)}
                      onChange={() => onSelect(user.id)}
                    />
                  </div>
                </td>
              )}
              <td>
                <IdCell id={user.id} browser={NavBarEnum.USERS_BROWSER} />
              </td>
              <td>
                <div className={'flex-center flex-nowrap'}>
                  <span>{user.name}</span>
                  {user.roles.includes(UserRole.Admin) && (
                    <span
                      className={
                        'flex-center ml-2 h-5 w-5 select-none rounded-full bg-[#C52E2E] text-center text-white'
                      }
                    >
                      A
                    </span>
                  )}
                </div>
              </td>
              <td>{user.roles}</td>
              <td>{user.email}</td>
              <td>{user.schoolName}</td>
              <td>{user.graduationYear}</td>
              <td>{user.parentEmail}</td>
              <td>{user.authProvider}</td>
            </tr>
          );
        })}
      </Table>

      <Modal
        onClose={onCloseCreateUser}
        isOpen={createUser}
        title={'Create new User'}
      >
        <CreateUserForm onCloseModal={onCloseCreateUser} />
      </Modal>
    </div>
  );
};
export default UsersBrowser;
