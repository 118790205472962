import { useSuspenseQuery } from '@apollo/client';
import { GET_WATERMARK } from '../../apollo/watermark';
import { useGetMe, useIsDesktop } from '../../utils/hooks';
import { WatermarkProps } from './types';

const Watermark = ({
  params,
  ...props
}: WatermarkProps): JSX.Element | false => {
  const { user } = useGetMe();
  const { windowWidth } = useIsDesktop();

  const { data } = useSuspenseQuery(GET_WATERMARK, { errorPolicy: 'all' });

  const currentParams = { ...(params ?? data!.watermark.get) };

  const getValidOpacity = (value: number) => {
    if (value === 100) return 1;
    return `${value}`.length === 1 ? `0.0${value}` : `0.${value}`;
  };

  const {
    logo,
    text,
    angle,
    textOpacity,
    logoOpacity,
    textCount,
    logoIndex,
    textSize,
    logoSize,
  } = currentParams;

  const waterMarkText = `${user?.name} ${user?.schoolName} ${user?.graduationYear}`;

  const isDesktopWidth = windowWidth > 768;

  const fontSize = isDesktopWidth
    ? ((window.innerWidth / waterMarkText.length) * textSize) / 50
    : 1000 / waterMarkText.length;
  const logoHeight = isDesktopWidth
    ? ((window.innerWidth / waterMarkText.length) * logoSize) / 50
    : 1000 / waterMarkText.length;

  const isVanishWatermark = !user?.watermark;

  if (isVanishWatermark) return false;

  return (
    <div
      className={
        'pointer-events-none absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center gap-3'
      }
      style={{
        transform: `rotate(${angle}deg)`,
        zIndex: -1,
      }}
      {...props}
    >
      {[...Array(textCount + Number(logo))].map((_, index) => {
        if (index === logoIndex && logo) {
          return (
            <img
              key={`logo-${index}`}
              src={`${import.meta.env.VITE_UPLOAD_S3_HOST}/logo-color.png`}
              alt="Logo"
              style={{
                height: logoHeight,
                opacity: getValidOpacity(logoOpacity),
              }}
            />
          );
        }

        if (text) {
          return (
            <div
              key={`text-${index}`}
              style={{
                userSelect: 'none',
                fontSize: `${fontSize}px`,
                color: 'black',
                whiteSpace: 'nowrap',
                opacity: getValidOpacity(textOpacity),
              }}
            >
              {waterMarkText}
            </div>
          );
        }

        return null;
      })}
    </div>
  );
};

export default Watermark;
