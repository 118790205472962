import { ElementType } from '../../utils/enums.ts';
import Autocomplete, { IAutocompleteOption } from '../common/autocomplete.tsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_FILTERED_QUIZZES,
  GET_FILTERED_TESTS,
} from '../../apollo/answers-view.ts';
import { Sort } from '../../apollo/__generated__/graphql.ts';

type IProps = {
  type: ElementType;
  onChange: (element: IAutocompleteOption) => void;
  courseId?: number;
};

export const ElementSelect = ({ type, onChange, courseId }: IProps) => {
  const [quizName, setQuizName] = useState<string>();
  const [testName, setTestName] = useState<string>();
  const [element, setElement] = useState<IAutocompleteOption>();
  const { data: quizzesData } = useQuery(GET_FILTERED_QUIZZES, {
    skip: type !== ElementType.QUIZ,
    variables: {
      paging: { limit: 50 },
      filter: {
        name: quizName,
        ...(courseId && { courseId }),
      },
      sort: { name: Sort.Asc },
    },
  });
  const { data: testsData } = useQuery(GET_FILTERED_TESTS, {
    skip: type !== ElementType.TEST,
    variables: {
      paging: { limit: 50 },
      filter: {
        name: testName,
        ...(courseId && { courseId }),
      },
      sort: { name: Sort.Asc },
    },
  });

  const quizzesOptions = useMemo(() => {
    return quizzesData?.quiz?.getQuizzes?.results.map((quiz) => ({
      title: quiz.name,
      key: quiz.id.toString(),
    }));
  }, [quizzesData?.quiz?.getQuizzes?.results]);

  const testsOptions = useMemo(() => {
    return testsData?.test?.getTests?.results.map((test) => ({
      title: test.name,
      key: test.id.toString(),
    }));
  }, [testsData?.test?.getTests?.results]);

  const onChangeValue = useCallback(
    (value?: string) => {
      switch (type) {
        case ElementType.QUIZ:
          setQuizName(value);
          break;
        case ElementType.TEST:
          setTestName(value);
          break;
      }
    },
    [type],
  );

  const setValue = useCallback(
    (value: IAutocompleteOption | IAutocompleteOption[]) => {
      if (Array.isArray(value)) return;
      onChange(value);
      setElement(value);
    },
    [onChange],
  );

  useEffect(() => {
    setElement(undefined);
  }, [type]);

  return (
    <Autocomplete
      value={element}
      className={'mt-2'}
      placeholder={
        type === ElementType.TEST ? 'Select test...' : 'Select quiz...'
      }
      options={type === ElementType.TEST ? testsOptions : quizzesOptions}
      setValue={setValue}
      onChangeValue={onChangeValue}
    />
  );
};
