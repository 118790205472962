import Breadcrumbs from '../../../components/common/breadcrumbs.tsx';
import { useSuspenseQuery } from '@apollo/client';
import { GET_COURSE } from '../../../apollo/courses.ts';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../../components/common/button.tsx';
import RemoveModal from '../../../components/common/remove-modal.tsx';
import RemoveButton from '../../../components/common/remove-button.tsx';
import { useRemoveCourse } from '../../../components/courses/courses-hooks.tsx';
import InfoTable, {
  IInfoTable,
} from '../../../components/common/info-table.tsx';
import StudentsTab from '../../../components/courses/tabs/students-tab.tsx';
import { useState } from 'react';
import Modal from '../../../components/common/modal.tsx';
import CreateCourseForm from '../../../components/courses/create-course-form.tsx';
import Tabs from '../../../components/common/tabs.tsx';
import QuizzesTab from '../../../components/courses/tabs/quizzes-tab.tsx';
import TestsTab from '../../../components/courses/tabs/tests-tab.tsx';
import { getDateString, getTimeForInput } from '../../../utils/common-utils.ts';
import { useGetMe } from '../../../utils/hooks.ts';
import AnswersView from '../../../components/answers-view/answers-view.tsx';

const CourseInformation = () => {
  const { isAdmin, isTeacher, isTA, isStaff } = useGetMe();
  const navigate = useNavigate();
  const params = useParams();
  const courseId = params?.courseId;
  const [isEdit, setEdit] = useState(false);
  const redirect = () => navigate('/courses-browser', { replace: true });
  const remove = useRemoveCourse(redirect);

  const { data } = useSuspenseQuery(GET_COURSE, {
    variables: { input: { id: Number(courseId) } },
    errorPolicy: 'all',
  });
  const course = data?.course.getCourse;

  const table: IInfoTable = [
    {
      head: 'Name',
      value: course?.name,
    },
    {
      head: 'Description',
      value: course?.description ?? '',
    },
    {
      head: 'Subject',
      value: course?.subject,
    },
    {
      head: 'Enrolled students',
      value: course?.userCount,
    },
    {
      head: 'Will archive on',
      value: `${getDateString(course?.archiveAt)} ${getTimeForInput(course?.archiveAt) || ''}`,
    },
    {
      head: 'Create by',
      value: course?.createdBy?.name || '(Deleted user)',
    },
  ];

  const onCloseEdit = () => setEdit(false);

  let tabs = ['Students', 'Quizzes', 'Tests'];
  let components = [
    <StudentsTab course={course} />,
    <QuizzesTab course={course} />,
    <TestsTab course={course} />,
  ];

  const isHasPermissionForViewScores = isAdmin || isTeacher || isTA || isStaff;

  if (isHasPermissionForViewScores) {
    tabs = [...tabs, 'Scores'];
    components = [...components, <AnswersView courseId={course?.id} />];
  }

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'Courses Browser', href: '/courses-browser' },
          { title: course?.name || '' },
        ]}
      />
      <div className={'flex items-center justify-between'}>
        <div className={'mt-4'}>
          <h1>Course Information</h1>
          <p className={'description'}>
            Basic information and details about the course
          </p>
        </div>

        <div className={'flex'}>
          <Button onClick={() => setEdit(true)} className={'mx-2'}>
            Edit
          </Button>
          {(isAdmin || isTeacher) && (
            <RemoveButton
              disabled={!isAdmin && !isTeacher}
              onClick={remove.onOpenModal}
            />
          )}
        </div>
      </div>

      <InfoTable table={table} />

      <Tabs tabs={tabs} components={components} />

      <Modal isOpen={isEdit} onClose={onCloseEdit} title={'Edit Course'}>
        <CreateCourseForm course={course} onClose={onCloseEdit} />
      </Modal>

      <RemoveModal
        onRemove={() => (course ? remove.onRemoveCourse(course.id) : null)}
        onClose={remove.onCloseModal}
        open={remove.isModalOpen}
      />
    </div>
  );
};
export default CourseInformation;
