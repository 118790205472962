import Breadcrumbs from '../common/breadcrumbs.tsx';
import Tabs from '../common/tabs.tsx';
import AssignmentsGroup from '../tasks/assignments-group.tsx';
import { IAssigmentCard } from '../tasks/assignment-card.tsx';
import { getDateString } from '../../utils/common-utils.ts';
import { maxBy } from 'lodash';
import {
  CourseStatus,
  GetUserCourseQuery,
  QuizAttemptStatus,
  TestAttemptStatus,
} from '../../apollo/__generated__/graphql.ts';
import { useFragment } from '../../apollo/__generated__';
import { COURSE_ELEMENT_WITH_ATTEMPTS_FRAGMENT } from '../../apollo/courses.ts';
import { ArrayElement } from '../../utils/types.ts';

export type Member = { type: 'member' } & ArrayElement<
  GetUserCourseQuery['user']['getUser']['members']
>;

export type Purchase = { type: 'purchase' } & ArrayElement<
  GetUserCourseQuery['user']['getUser']['purchases']
>;

interface IProps {
  member?: Member | Purchase;
}

const Course = ({ member }: IProps) => {
  const elements = useFragment(
    COURSE_ELEMENT_WITH_ATTEMPTS_FRAGMENT,
    member?.course?.elements,
  );
  const isArchive = member?.course?.status === CourseStatus.Archive;

  const testsCards: IAssigmentCard[] = [];
  const quizzesCards: IAssigmentCard[] = [];

  elements?.forEach((el) => {
    if (el.test) {
      const attempts = el.test?.attempts;
      const lastAttempt = maxBy(
        attempts,
        (attempt) => new Date(attempt.createdAt),
      );
      const isCompleted = !!attempts?.some(
        (item) => item.status === TestAttemptStatus.Completed,
      );
      testsCards.push({
        order: el.order,
        type: 'Test',
        title: el.test.name,
        id: el.test.id,
        assignedOn: getDateString(member?.createdAt),
        assignedBy:
          member?.type === 'member'
            ? member?.enrolledBy?.name || ''
            : member?.user?.name || '',
        status: isArchive
          ? 'Archived'
          : lastAttempt
            ? isCompleted
              ? 'Completed'
              : 'In progress'
            : 'Not started',
        lastAttemptStatus: lastAttempt?.status,
        testType: el.test.type.name,
        subject: el.test.subject.name,
        test: {
          slug: el.test.slug,
          answersReportUrl: lastAttempt?.answerReportUrl,
          scoreReportUrl: lastAttempt?.scoreReportUrl,
          lastAttemptId: lastAttempt?.id,
        },
      });
    }
    if (el.quiz) {
      const attempts = el.quiz?.attempts;
      const lastAttempt = maxBy(
        attempts,
        (attempt) => new Date(attempt.createdAt),
      );
      const isCompleted = !!attempts?.some(
        (item) => item.status === QuizAttemptStatus.Completed,
      );
      quizzesCards.push({
        order: el.order,
        type: 'Quiz',
        title: el.quiz?.name || '',
        id: el.quiz.id,
        assignedOn: getDateString(member?.createdAt),
        assignedBy:
          member?.type === 'member'
            ? member?.enrolledBy?.name || ''
            : member?.user?.name || '',
        status: isArchive
          ? 'Archived'
          : lastAttempt
            ? isCompleted
              ? 'Completed'
              : 'In progress'
            : 'Not started',
        lastAttemptStatus: lastAttempt?.status,
        testType: el.quiz.type.name,
        subject: el.quiz.subject.name,
        quiz: {
          slug: el.quiz.slug,
          lastAttemptId: lastAttempt?.id,
        },
      });
    }
  });
  quizzesCards.sort((a, b) => (a.order || 0) - (b.order || 0));
  testsCards.sort((a, b) => (a.order || 0) - (b.order || 0));

  if (!member) return null;
  return (
    <>
      <Breadcrumbs
        elements={[
          { title: 'Courses', href: '/courses' },
          { title: member?.course?.name || '' },
        ]}
      />
      <h1 className={'my-4'}>{member?.course?.name}</h1>

      {member?.course?.description && (
        <section className={'text-sm'}>
          <h2 className={'font-semibold'}>About the course</h2>
          <p className={'mt-2 text-gray'}>{member.course.description}</p>
        </section>
      )}

      <h1 className={'mt-5'}>
        Assignments
        <span className={'amount-indicator'}>{elements?.length}</span>
      </h1>

      <section>
        <Tabs
          tabs={[
            `All (${elements?.length})`,
            `Quizzes (${member.course?.quizCount})`,
            `Tests (${member.course?.testCount})`,
          ]}
          components={[
            <AssignmentsGroup assignments={[...testsCards, ...quizzesCards]} />,
            <AssignmentsGroup assignments={quizzesCards} />,
            <AssignmentsGroup assignments={testsCards} />,
          ]}
        />
      </section>
    </>
  );
};
export default Course;
