import { gql } from './__generated__';

export const QUIZ_FRAGMENT = gql(/* GraphQL */ `
  fragment QuizFragment on Quiz {
    attempts {
      id
    }
    created
    createdBy {
      name
    }
    description
    elements {
      id
    }
    id
    name
    questionCount
    slug
    status
    subject {
      id
      name
    }
    type {
      id
      name
    }
    quizQuestions {
      id
      order
      question {
        id
        originalId
        prompt
        explanation
        content
        answerType
        choices {
          id
          order
          ...ChoicesFragment
        }
        section {
          id
          name
        }
        difficulty {
          id
        }
        skill {
          name
        }
        domain {
          name
        }
      }
    }
  }
`);

export const QUIZ_FRAGMENT_OMIT_QUESTIONS = gql(/* GraphQL */ `
  fragment QuizFragmentOmitQuestions on Quiz {
    attempts {
      id
    }
    created
    createdBy {
      id
      name
    }
    description
    elements {
      id
    }
    id
    name
    questionCount
    slug
    status
    subject {
      id
      name
    }
    type {
      id
      name
    }
  }
`);

export const QUIZ_RESULTS_FRAGMENT = gql(/* GraphQL */ `
  fragment QuizResultsFragment on Quiz {
    id
    quizQuestions {
      order
      question {
        id
      }
    }
  }
`);

export const GET_QUIZZES = gql(/* GraphQL */ `
  query getQuizzes(
    $filter: QuizFilterInput
    $paging: PagingInput
    $sort: QuizSortInput
  ) {
    quiz {
      getQuizzes(filter: $filter, paging: $paging, sort: $sort) {
        limit
        page
        total
        results {
          id
          ...QuizFragmentOmitQuestions
        }
      }
    }
  }
`);

export const GET_QUIZ = gql(/* GraphQL */ `
  query getQuiz($input: GetQuizInput!) {
    quiz {
      getQuiz(input: $input) {
        ...QuizFragment
      }
    }
  }
`);

export const UPDATE_QUIZ = gql(/* GraphQL */ `
  mutation updateQuiz($input: UpdateQuizInput!) {
    quiz {
      update(input: $input) {
        attempts {
          id
        }
        createdBy {
          name
        }
        description
        elements {
          id
        }
        id
        name
        questionCount
        slug
        status
        quizQuestions {
          id
          order
          question {
            id
            prompt
            content
            answerType
            difficulty {
              name
            }
            skill {
              name
            }
            domain {
              name
            }
          }
        }
      }
    }
  }
`);

export const REMOVE_QUIZ = gql(/* GraphQL */ `
  mutation removeQuiz($input: DeleteQuizzesInput!) {
    quiz {
      delete(input: $input) {
        affected
      }
    }
  }
`);

export const CREATE_QUIZ = gql(/* GraphQL */ `
  mutation createQuiz($input: CreateQuizInput!) {
    quiz {
      create(input: $input) {
        ...QuizFragment
      }
    }
  }
`);

export const COPY_QUIZ = gql(/* GraphQL */ `
  mutation copyQuiz($input: CopyQuizzesInput!) {
    quiz {
      copy(input: $input) {
        id
        slug
      }
    }
  }
`);
