import CheckMarkIcon from '../../../assets/icons/checkmark.svg?react';
import { ChangeEvent } from 'react';
import Tooltip from '../../common/tooltip.tsx';
import Markdown from '../../markdown.tsx';
import { cn, orderToLetter } from '../../../utils/common-utils.ts';
import { specialCharacter } from '../../../utils/constants.ts';

interface IProps {
  isHeader?: boolean;
  text: string;
  value: number;
  order: number;
  isChecked: boolean;
  isCrossOutMode: boolean;
  onSetExcludedChoices?: (isCrossed: boolean, choiceId: number) => void;
  onChange?: (value?: string | number) => void;
  isRightAnswerMark?: boolean;
  isWrongAnswerMark?: boolean;
  isDisabled?: boolean;
  excluded?: boolean;
  isPreviewMode?: boolean;
  numberOfColumns?: number;
}

const QuestionAnswer = ({
  isHeader = false,
  text,
  order,
  value,
  isChecked,
  isCrossOutMode,
  onChange,
  isDisabled,
  excluded,
  onSetExcludedChoices,
  isRightAnswerMark = false,
  isWrongAnswerMark = false,
  isPreviewMode = false,
  numberOfColumns = 1,
}: IProps) => {
  const toggleCross = () => {
    onSetExcludedChoices?.(!excluded, value);
    if (onChange && isChecked) {
      onChange(undefined);
    }
  };
  const changeAnswer = ({ target }: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onSetExcludedChoices?.(false, value);
      onChange(Number(target.value));
    }
  };

  const id = 'answer-' + value;
  return (
    <div className={'flex items-center font-gentium text-lg'}>
      <div className={'flex flex-grow gap-x-2'}>
        {Array(numberOfColumns)
          .fill(true)
          .map((item, index) => {
            return (
              <label
                htmlFor={id}
                className={cn(
                  'relative flex w-full cursor-pointer flex-nowrap items-center justify-between rounded-lg border border-black py-2 font-medium transition-colors',
                  isChecked && !isPreviewMode && 'border-blue text-blue',
                  excluded && isCrossOutMode && 'text-gray',
                  isCrossOutMode && 'mr-3',
                  isWrongAnswerMark && 'border-red bg-light-red',
                )}
              >
                {excluded && isCrossOutMode && (
                  <div
                    className={
                      'absolute top-1/2 -ml-[1%] h-0.5 w-[102%] -translate-y-1/2 bg-black'
                    }
                  />
                )}
                <div className={'flex-center px-2'}>
                  {!isHeader && (
                    <span
                      className={cn(
                        'flex-center h-8 w-8 min-w-8 select-none rounded-full border font-bold',
                        isChecked && 'border-blue bg-blue text-white',
                        index > 0 && 'hidden',
                      )}
                    >
                      {orderToLetter(order)}
                    </span>
                  )}
                  <span className={'ml-2'}>
                    <Markdown>
                      {index < text.split(specialCharacter).length
                        ? text.split(specialCharacter)[index]
                        : ''}
                    </Markdown>
                  </span>
                </div>
              </label>
            );
          })}
      </div>
      <input
        className={'hidden'}
        name={'answers-group'}
        type={'radio'}
        id={id}
        value={value}
        checked={isChecked}
        disabled={isDisabled}
        onChange={changeAnswer}
      />
      {!isHeader && isCrossOutMode && (
        <div className={'flex w-8 justify-center'}>
          <Tooltip tip={excluded ? 'Remove mark' : 'Mark as incorrect'}>
            <button
              onClick={toggleCross}
              className={cn(
                'flex-center relative size-6 cursor-pointer select-none text-xs font-bold',
                !excluded
                  ? 'rounded-full border border-black'
                  : 'font-sans text-black underline',
              )}
            >
              {!excluded && (
                <div
                  className={
                    'absolute top-1/2 h-0.5 w-7 -translate-y-1/2 bg-black'
                  }
                />
              )}
              {excluded ? 'Undo' : orderToLetter(order)}
            </button>
          </Tooltip>
        </div>
      )}
      {isPreviewMode && (
        <div className="ml-2 w-4">
          {isRightAnswerMark && <CheckMarkIcon fill={'#43A95F'} />}
          {isWrongAnswerMark && <CheckMarkIcon fill={'#921818'} />}
        </div>
      )}
    </div>
  );
};
export default QuestionAnswer;
