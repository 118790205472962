import { cn, getDateString } from '../../utils/common-utils.ts';
import Button from '../common/button.tsx';
import { Link } from 'react-router-dom';
import {
  GetUserActivitiesQuery,
  QuizAttemptStatus,
  TestAttemptStatus,
} from '../../apollo/__generated__/graphql.ts';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useCallback,
  useMemo,
} from 'react';
import { ActivityStatus } from '../../utils/enums.ts';
import { useUpdateReports } from '../test-attempt/test-attempt-hooks.ts';
import Loading from '../common/loading.tsx';
import { convertQuizScore } from '../quiz-attempt/quiz-attempt-utils.ts';

interface IProps {
  attempt: GetUserActivitiesQuery['userActivity']['getActivities']['results'][0];
  setQuizQuestionsReviewId?: Dispatch<SetStateAction<number | undefined>>;
  setQuizAttemptReviewId?: Dispatch<SetStateAction<number | undefined>>;
}

export const UserActivityItem = forwardRef<HTMLDivElement, IProps>(
  ({ attempt, setQuizQuestionsReviewId, setQuizAttemptReviewId }, ref) => {
    const { updateReports, updatingReports } = useUpdateReports();

    const getReport = useCallback((url?: string | null) => {
      if (url) window.open(url, '_blank');
    }, []);

    const status = useMemo(() => {
      switch (attempt.__typename) {
        case 'TestAttempt':
          return ActivityStatus[attempt.tStatus];
        case 'QuizAttempt':
          return ActivityStatus[attempt.qStatus];
        default:
          return 'Undefined';
      }
    }, [attempt]);

    const name = useMemo(() => {
      switch (attempt.__typename) {
        case 'TestAttempt':
          return (
            <>
              <span className={'font-semibold'}>{attempt.test?.name}</span>{' '}
              test.
            </>
          );
        case 'QuizAttempt':
          return (
            <>
              <span className={'font-semibold'}>{attempt.quiz?.name}</span>{' '}
              quiz.
            </>
          );
        default:
          return 'Undefined';
      }
    }, [attempt]);

    return (
      <div
        ref={ref}
        className={
          'flex items-center justify-between rounded border border-table-border p-2 text-sm'
        }
      >
        <div>
          <span
            className={cn(
              'mr-2 inline-block w-24 rounded-xl p-1 text-center text-xs text-white',
              status === ActivityStatus.Completed
                ? 'bg-green'
                : status === ActivityStatus.InProgress
                  ? 'bg-blue'
                  : 'bg-red',
            )}
          >
            {status}
          </span>
          <span>{getDateString(attempt.createdAt)}</span>
          <span className={'mx-2'}>Started an attempt of {name}</span>
        </div>

        <div className={'flex items-center gap-1'}>
          {attempt.__typename === 'QuizAttempt' &&
            attempt.qStatus === QuizAttemptStatus.Completed && (
              <span>
                {attempt.correctQuestionsCount}/{attempt.quiz.questionCount} (
                {convertQuizScore(
                  attempt.correctQuestionsCount / attempt.quiz.questionCount,
                )}
                )
              </span>
            )}
          <Link
            target={'_blank'}
            to={
              attempt.__typename === 'TestAttempt'
                ? `/inspect-test-attempt/${attempt.id}`
                : `/inspect-quiz-attempt/${attempt.id}`
            }
            className={'link-white-button'}
          >
            Inspect
          </Link>
          {attempt.__typename === 'TestAttempt' && attempt.answerReportUrl && (
            <Button
              white
              onClick={() => getReport(attempt.answerReportUrl)}
              disabled={updatingReports}
            >
              Answer Report
            </Button>
          )}
          {attempt.__typename === 'TestAttempt' && attempt.scoreReportUrl && (
            <Button
              white
              onClick={() => getReport(attempt.scoreReportUrl)}
              disabled={updatingReports}
            >
              Score Report
            </Button>
          )}
          {attempt.__typename === 'TestAttempt' &&
            attempt.tStatus === TestAttemptStatus.Completed && (
              <Button
                white
                onClick={() => updateReports(attempt.id)}
                disabled={updatingReports}
              >
                Refresh Reports
              </Button>
            )}
          {updatingReports && (
            <Loading isSmall={true} loaderClassName={'h-8'} />
          )}

          {attempt.__typename === 'QuizAttempt' &&
            attempt.qStatus === QuizAttemptStatus.Completed && (
              <>
                <Button
                  white
                  onClick={() => setQuizQuestionsReviewId?.(attempt.id)}
                >
                  Review
                </Button>
                <Button
                  white
                  onClick={() => setQuizAttemptReviewId?.(attempt.id)}
                >
                  View Answers
                </Button>
              </>
            )}
        </div>
      </div>
    );
  },
);
