import {
  ScriptSortInput,
  ScriptTableFragment,
} from '../../../apollo/__generated__/graphql.ts';
import { ISortObj } from '../../table/table-utils.ts';

type IColumn = {
  title: string;
  key: keyof ScriptTableFragment | '_checkbox';
};

type IScriptSortKeys = keyof ScriptSortInput;
export const scriptsSortKeys: IScriptSortKeys[] = [
  'id',
  'name',
  'createdAt',
  'createdBy',
  'satSection',
];

export const scriptsColumns: IColumn[] = [
  { key: 'id', title: 'Id' },
  { key: 'name', title: 'Name' },
  { key: 'satSection', title: 'SAT Section' },
  { key: 'createdBy', title: 'Created by' },
  { key: 'createdAt', title: 'Created at' },
  { key: 'type', title: 'Test Type' },
];

export const scriptsDefaultSort: ISortObj = {
  id: undefined,
  name: undefined,
  createdAt: undefined,
  createdBy: undefined,
  satSection: undefined,
};

export const recommendedQuestionCountPerDomain: Record<string, string> = {
  'craft and structure': '13-15',
  'information and ideas': '12-14',
  'standard english conventions': '11-15',
  'expression of ideas': '8-12',
  algebra: '13-15',
  'advanced math': '13-15',
  'problem-solving and data analysis': '5-7',
  'geometry and trigonometry': '5-7',
};
