import Breadcrumbs from '../../components/common/breadcrumbs.tsx';
import { useMutation, useSuspenseQuery } from '@apollo/client';
import { GET_QUIZ, QUIZ_FRAGMENT, UPDATE_QUIZ } from '../../apollo/quizzes.ts';
import { useNavigate, useParams } from 'react-router-dom';
import { useFragment } from '../../apollo/__generated__';
import Button from '../../components/common/button.tsx';
import { useCallback, useState } from 'react';
import Select from '../../components/common/select.tsx';
import {
  OrderedQuestionsInput,
  QuizStatus,
} from '../../apollo/__generated__/graphql.ts';
import { toast } from 'react-toastify';
import Modal from '../../components/common/modal.tsx';
import CreateQuizForm from './create-quiz-form.tsx';
import {
  createQuestionNameForQuiz,
  shuffleArray,
} from '../../components/quizzes-browser/quizzes-utils.tsx';
import QuizQuestion from '../../components/quizzes-browser/quiz-question.tsx';
import InfoTable, { IInfoTable } from '../../components/common/info-table.tsx';
import { useRemoveQuizzes } from '../../components/quizzes-browser/quizzes-hooks.tsx';
import AddQuestionsModal from '../../components/questions/add-questions-modal.tsx';
import RemoveModal from '../../components/common/remove-modal.tsx';
import RemoveButton from '../../components/common/remove-button.tsx';
import { Popover } from '../../components/common/popover.tsx';
import {
  IQuestionsExportSettingsFormData,
  QuestionsExportSettingsForm,
} from '../../components/questions/questions-export-settings-form.tsx';
import ActionsDropdown from '../../components/table/actions-dropdown.tsx';
import { useGetMe } from '../../utils/hooks.ts';
import { createPdfExportUrl } from '../../utils/common-utils.ts';
import { NavBarEnum } from '../../utils/enums.ts';

const QuizInformation = () => {
  const [isRemoveAllModal, setRemoveAllModal] = useState(false);
  const [isQuestionsBrowser, setQuestionsBrowser] = useState(false);
  const [isQuizEditModal, setQuizEditModal] = useState(false);
  const [selectedQuestions, setSelectedQuestion] = useState<number[]>([]);
  const [questionToChange, setQuestionToChange] = useState<number | null>(null);

  const { isAdmin, isTeacher } = useGetMe();
  const navigate = useNavigate();
  const params = useParams();
  const slug = params?.slug;

  const redirect = () => navigate('/quizzes-browser', { replace: true });
  const { onRemoveQuizzes, onCloseRemove, onOpenRemove, removeModal } =
    useRemoveQuizzes(redirect);
  const { data } = useSuspenseQuery(GET_QUIZ, {
    variables: {
      input: {
        slug,
      },
    },
    errorPolicy: 'all',
  });
  const quiz = useFragment(QUIZ_FRAGMENT, data?.quiz?.getQuiz);
  const currentQuestionsIds =
    quiz?.quizQuestions?.reduce<number[]>((acc, { question }) => {
      if (question) {
        acc.push(question.id);
      }
      return acc;
    }, []) || [];
  const [update] = useMutation(UPDATE_QUIZ);

  const onChangeStatus = (value: QuizStatus) => {
    if (!quiz) return null;
    const updatePromise = update({
      variables: {
        input: {
          status: value,
          id: quiz.id,
          name: quiz.name,
          orderedQuestions: currentQuestionsIds.map((id, index) => ({
            questionId: id,
            order: index,
          })),
          subjectId: quiz.subject.id,
          typeId: quiz.type.id,
        },
      },
    });

    toast.promise(updatePromise, {
      success: 'Status changed',
      pending: 'Updating status...',
    });
  };

  const onAddQuestions = (ids: number[]) => {
    if (!quiz) return null;
    const array: OrderedQuestionsInput[] = currentQuestionsIds.map(
      (id, index) => ({
        questionId: id,
        order: index,
      }),
    );

    ids.forEach((id) => {
      array.push({
        questionId: id,
        order: array.length,
      });
    });

    const updatePromise = update({
      variables: {
        input: {
          id: quiz.id,
          name: quiz.name,
          status: quiz.status,
          orderedQuestions: array,
          subjectId: quiz.subject.id,
          typeId: quiz.type.id,
        },
      },
    });

    toast.promise(updatePromise, {
      success: ids.length > 1 ? 'Questions added' : 'Question added',
      pending: ids.length > 1 ? 'Adding questions' : 'Adding a question',
    });
    updatePromise.then(() => onCloseQuestionBrowser());
  };
  const onChangeQuestion = (newId: number) => {
    if (!quiz || questionToChange == null) return;
    const updatePromise = update({
      variables: {
        input: {
          id: quiz.id,
          name: quiz.name,
          status: quiz.status,
          subjectId: quiz.subject.id,
          typeId: quiz.type.id,
          orderedQuestions: currentQuestionsIds.map((id, index) => {
            return {
              questionId: id === questionToChange ? newId : id,
              order: index,
            };
          }),
        },
      },
    });

    toast.promise(updatePromise, {
      success: 'Question replaced',
      pending: 'Replace the question...',
    });
    updatePromise.then(() => onCloseQuestionBrowser());
  };

  const onRemoveQuestions = (ids: number[]) => {
    if (!quiz) return null;
    const updatePromise = update({
      variables: {
        input: {
          orderedQuestions: currentQuestionsIds
            .filter((id) => !ids.includes(id))
            .map((id, index) => ({ questionId: id, order: index })),
          id: quiz.id,
          name: quiz.name,
          status: quiz.status,
          subjectId: quiz.subject.id,
          typeId: quiz.type.id,
        },
      },
    });
    toast.promise(updatePromise, {
      success: ids.length > 1 ? 'Questions removed' : 'Question removed',
      pending: ids.length > 1 ? 'Removing questions' : 'Removing a question',
    });
  };

  const onOpenQuestionBrowser = (id?: number) => {
    if (typeof id === 'number') setQuestionToChange(id);
    setQuestionsBrowser(true);
  };
  const onCloseQuestionBrowser = () => {
    setQuestionToChange(null);
    setSelectedQuestion([]);
    setQuestionsBrowser(false);
  };
  const onCloseEditQuiz = () => setQuizEditModal(false);
  const onUpdateQuizName = (slug: string) => {
    onCloseEditQuiz();
    navigate(`/quizzes-browser/${slug}`, { replace: true });
  };

  const onEditQuiz = () => setQuizEditModal(true);

  const table: IInfoTable = [
    {
      head: 'Name',
      value: quiz?.name,
    },
    {
      head: 'Description',
      value: quiz?.description || '',
    },
    {
      head: 'Subject',
      value: quiz?.subject.name,
    },
    {
      head: 'Type',
      value: quiz?.type.name,
    },
    {
      head: 'Status',
      value: (
        <Select
          value={quiz?.status || QuizStatus.Draft}
          options={[QuizStatus.Draft, QuizStatus.Active]}
          onChange={(value) => onChangeStatus(value as QuizStatus)}
        />
      ),
    },
    {
      head: 'Questions',
      value: quiz?.questionCount || 0,
    },
    {
      head: 'Created by',
      value: quiz?.createdBy?.name || '(Deleted user)',
    },
  ];

  const onClickRandom = () => {
    if (!quiz) return null;
    const updatePromise = update({
      variables: {
        input: {
          id: quiz.id,
          name: quiz.name,
          status: quiz.status,
          subjectId: quiz.subject.id,
          typeId: quiz.type.id,
          orderedQuestions: shuffleArray(currentQuestionsIds).map(
            (id, index) => ({ questionId: id, order: index }),
          ),
        },
      },
    });

    toast.promise(updatePromise, {
      success: 'Questions are arranged in random order',
      pending: 'Arrange the questions in random order...',
    });
  };

  const onCloseRemoveAllModal = () => setRemoveAllModal(false);
  const onRemoveAll = () => {
    if (!quiz) return null;
    const updatePromise = update({
      variables: {
        input: {
          id: quiz.id,
          name: quiz.name,
          status: QuizStatus.Draft,
          orderedQuestions: [],
          subjectId: quiz.subject.id,
          typeId: quiz.type.id,
        },
      },
    });

    updatePromise.then(() => onCloseRemoveAllModal());
    toast.promise(updatePromise, {
      success: 'All questions have been removed from the quiz',
      pending: 'Deleting all questions in quiz...',
    });
  };

  const onExport = useCallback(
    (params: IQuestionsExportSettingsFormData) => {
      if (!quiz?.id) return null;
      const url = createPdfExportUrl('quiz', {
        id: quiz.id.toString(),
        ...params,
      });
      window.open(url);
    },
    [quiz?.id],
  );

  return (
    <div>
      <Breadcrumbs
        elements={[
          { title: 'quizzes', href: '/quizzes-browser' },
          { title: quiz?.name || '' },
        ]}
      />
      <div className={'mt-4 flex justify-between'}>
        <div>
          <h1>Quiz Information</h1>
          <p className={'description'}>
            Basic information and details about the quiz
          </p>
        </div>
        <div className={'flex gap-2'}>
          <Button onClick={onEditQuiz}>Edit quiz</Button>
          <ActionsDropdown
            selected={quiz ? [quiz.id] : []}
            browser={NavBarEnum.QUIZZES_BROWSER}
          />
          <Popover
            triggerComponent={
              <button
                className={
                  'box-border flex h-fit items-center justify-center rounded bg-light-blue p-2 text-sm font-medium text-black'
                }
              >
                Export
              </button>
            }
            contentProps={{ className: 'w-80' }}
          >
            <div className="grid gap-4">
              <div className="space-y-2">
                <h4 className="font-medium leading-none">Export</h4>
                <p className={'description'}>Set the quiz export settings</p>
              </div>
              <QuestionsExportSettingsForm onSubmit={onExport} />
            </div>
          </Popover>
          {(isAdmin || isTeacher) && <RemoveButton onClick={onOpenRemove} />}
        </div>
      </div>

      <InfoTable table={table} />

      <section className={'mt-8'}>
        <div className={'flex items-center justify-between'}>
          <div>
            <h1>Questions</h1>
            <p className={'description'}>
              A list of all the questions linked to this section
            </p>
          </div>
          <div className={'flex'}>
            <Button
              onClick={onClickRandom}
              disabled={currentQuestionsIds.length < 2}
              white
              className={'mx-2'}
            >
              Random
            </Button>
            <RemoveButton
              onClick={() => setRemoveAllModal(true)}
              disabled={!currentQuestionsIds.length}
            />
          </div>
        </div>

        <div className={'mt-4'}>
          {quiz?.quizQuestions?.map((quizQuestion) => {
            if (!quizQuestion.question) return null;
            return (
              <QuizQuestion
                key={quizQuestion.id}
                name={createQuestionNameForQuiz(
                  quizQuestion.question.originalId,
                  quizQuestion.question.content,
                  quizQuestion.question.domain?.name,
                  quizQuestion.question.difficulty.id,
                  quizQuestion.question.skill?.name,
                  quizQuestion.question.prompt,
                )}
                number={quizQuestion.order + 1}
                onRemoveQuestion={() =>
                  onRemoveQuestions([quizQuestion.question!.id])
                }
                onClickQuestion={() =>
                  onOpenQuestionBrowser(quizQuestion.question!.id)
                }
              />
            );
          })}
          <QuizQuestion
            name={'+ Add question'}
            onClickQuestion={onOpenQuestionBrowser}
          />
        </div>
      </section>

      <AddQuestionsModal
        open={isQuestionsBrowser}
        onClose={onCloseQuestionBrowser}
        onAddQuestions={onAddQuestions}
        onChangeQuestion={onChangeQuestion}
        questionToChange={questionToChange}
        setSelectedQuestion={setSelectedQuestion}
        selectedQuestions={selectedQuestions}
      />

      <Modal
        title={'Edit quiz'}
        isOpen={isQuizEditModal}
        onClose={() => setQuizEditModal(false)}
      >
        <CreateQuizForm quiz={quiz} onCloseModal={onUpdateQuizName} />
      </Modal>

      <RemoveModal
        open={isRemoveAllModal}
        description={
          'This will permanently unlink all the questions of this quiz.'
        }
        onRemove={onRemoveAll}
        onClose={onCloseRemoveAllModal}
      />

      <RemoveModal
        open={removeModal}
        onRemove={() => onRemoveQuizzes(quiz ? [quiz.id] : [])}
        onClose={onCloseRemove}
      />
    </div>
  );
};
export default QuizInformation;
