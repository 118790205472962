import { Link } from 'react-router-dom';
import { IFilterType } from '../../../components/questions/questions-browser/questions-filter/filter-utils.ts';
import QuestionsBrowser from '../../../components/questions/questions-browser/questions-browser.tsx';
import { useGetMe } from '../../../utils/hooks.ts';

export interface IFilter {
  data: IFilterType;
  inputValue: string;
  id: string;
}

const QuestionsBrowserPage = () => {
  const { isTeacher, isAdmin, isVA } = useGetMe();

  const isHasPermissionForCreateQuestion = isAdmin || isVA || isTeacher;

  return (
    <div>
      <section className={'flex items-center justify-between'}>
        <div>
          <h1>{isTeacher ? 'My SAT Questions' : 'SAT Questions Browser'}</h1>
          <p className={'description'}>
            A list of all the questions in the database
          </p>
        </div>
        <div className={'flex'}>
          {isHasPermissionForCreateQuestion && (
            <Link className={'link-blue-button'} to={'/create-question'}>
              Create question
            </Link>
          )}
          <Link
            to={'/questions-browser/drafts'}
            className={'link-white-button ml-2'}
          >
            Drafts
          </Link>
        </div>
      </section>
      <QuestionsBrowser />
    </div>
  );
};
export default QuestionsBrowserPage;
