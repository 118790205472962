import TaskGroup from '../components/tasks/task-group.tsx';
import { useGetMe } from '../utils/hooks.ts';
import { useGetMyAssignments } from '../components/users/users-hooks.tsx';
import {
  CourseStatus,
  QuizAttemptStatus,
  TestAttemptStatus,
} from '../apollo/__generated__/graphql.ts';
import { ICard } from '../components/tasks/task-card.tsx';
import { orderBy } from 'lodash';
import { getDateString } from '../utils/common-utils.ts';
import { useSuspenseQuery } from '@apollo/client';
import { GET_MY_COURSES } from '../apollo/user-assignments.ts';
import { useMemo } from 'react';

const Home = () => {
  const { user } = useGetMe();
  const { tests, quizzes } = useGetMyAssignments(true);
  const { data: coursesData } = useSuspenseQuery(GET_MY_COURSES, {
    variables: {
      purchaseFilter: { courses: true },
      memberFilter: { status: CourseStatus.Active },
    },
    errorPolicy: 'all',
  });
  const members = coursesData?.user.me.members;
  const purchases = coursesData?.user.me.purchases;

  const completed: ICard[] = [];
  const inProgress: ICard[] = [];
  tests.forEach((el) => {
    if (el.test && el.test.status === 'Active') {
      const lastAttempt = el.test.attempts?.[0];
      if (lastAttempt) {
        const data: ICard = {
          id: el.test.id,
          title: el.test.name,
          createdAt: el.createdAt,
          type: 'Test',
          lastAttemptId: lastAttempt.id,
          testType: el.test.type.name,
          subject: el.test.subject.name,
        };
        if (lastAttempt.status === TestAttemptStatus.Completed) {
          data.completedAt = getDateString(lastAttempt.updatedAt);
          data.test = {
            mathScore: lastAttempt.mathScore,
            rwScore: lastAttempt.rwScore,
            testAttemptId: lastAttempt.id,
          };
          completed.push(data);
        } else {
          inProgress.push(data);
        }
      }
    }
  });

  quizzes.forEach((el) => {
    if (el.quiz && el.quiz.status === 'Active') {
      const lastAttempt = el.quiz.attempts?.[0];
      if (lastAttempt) {
        const data: ICard = {
          id: el.quiz.id,
          title: el.quiz.name,
          createdAt: el.createdAt,
          type: 'Quiz',
          lastAttemptId: lastAttempt.id,
          testType: el.quiz.type.name,
          subject: el.quiz.subject.name,
        };
        if (lastAttempt.status === QuizAttemptStatus.Completed) {
          data.completedAt = getDateString(lastAttempt.updatedAt);
          data.quiz = {
            ...(lastAttempt.score && {
              score: `${(lastAttempt.score * 100).toFixed(2)}%`,
            }),
            ...(lastAttempt.questions && {
              questionsData: `${lastAttempt.questions.filter((q) => q.correct).length}/${el.quiz.questionCount}`,
            }),
          };
          completed.push(data);
        } else {
          inProgress.push(data);
        }
      }
    }
  });

  const courses = useMemo(() => {
    const tempt: ICard[] = [];
    members?.forEach((el) => {
      if (el.course && el.course.status === 'Active') {
        tempt.push({
          id: el.course.id,
          title: el.course.name,
          description: el.course.description || '',
          type: 'Course',
        });
      }
    });
    purchases?.forEach((el) => {
      if (el.course && el.course.status === 'Active') {
        tempt.push({
          id: el.course.id,
          title: el.course.name,
          description: el.course.description || '',
          type: 'Course',
          purchase: true,
        });
      }
    });
    return tempt;
  }, [members, purchases]);

  return (
    <section>
      <h1>Welcome, {user?.name}</h1>
      {user?.schoolName && <p className={'description'}>{user.schoolName}</p>}

      <TaskGroup title={'Your Courses'} cards={courses} />
      <TaskGroup
        title={'Tests & Quizzes In Progress'}
        cards={orderBy(inProgress, (item) => new Date(item.createdAt!))}
      />
      <TaskGroup
        title={'Tests & Quizzes Completed'}
        cards={orderBy(completed, (item) => new Date(item.completedAt!))}
        isCompleted={true}
      />
    </section>
  );
};

export default Home;
