import { useState } from 'react';
import Watermark from '../../../components/watermark';
import WatermarkLocalParams from './watermark-local-params';
import WatermarkGlobalParams from './watermark-global-params';
import { useSuspenseQuery } from '@apollo/client';
import { GET_WATERMARK } from '../../../apollo/watermark';
import { useIsDesktop } from '../../../utils/hooks';

const WatermarkPage = (): JSX.Element => {
  const { isDesktop } = useIsDesktop();

  const { data } = useSuspenseQuery(GET_WATERMARK, { errorPolicy: 'all' });

  const [params, setParams] = useState(data?.watermark.get!);

  return (
    <div>
      <div>
        <h1>Watermark</h1>
        <p className={'description'}>Settings for manage Watermark</p>
        <hr className="my-2 opacity-10" />
      </div>

      {!isDesktop && <Watermark params={params} />}
      <div className="flex w-full gap-4">
        <div
          style={
            isDesktop
              ? {
                  borderRight: '2px solid rgba(80,80,80,0.1)',
                  paddingRight: 30,
                  minWidth: 400,
                }
              : { width: '100%' }
          }
        >
          <WatermarkLocalParams />
          <WatermarkGlobalParams {...{ params, setParams }} />
        </div>
        {isDesktop && (
          <div className={'relative flex-1 overflow-hidden'}>
            <Watermark params={params} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WatermarkPage;
